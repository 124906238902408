export const nonEcrfStyle = {
  nonEcrfForm: {
    label: { color: '#595959', fontSize: '16px' },
    labelDesc: { color: '#03333', fontSize: '16px' }
  },
  modal: {
    container: {
      minWidth: '80%',
      minHeight: '70%',
      maxHeight: '90%',
      padding: 0,
    },
  },
  targetTable: {
    tableWrapper: {
      maxHeight: '200px',
    }
  }
};