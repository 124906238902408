/*eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Grid from 'apollo-react/components/Grid';
import Box from '@mui/material/Box';
import TextField from 'apollo-react/components/TextField';
import Button from 'apollo-react/components/Button';
import Drag from 'apollo-react-icons/Drag';
import Switch from 'apollo-react/components/Switch';
import Save from 'apollo-react-icons/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from 'apollo-react/components/Accordion';
import AccordionDetails from 'apollo-react/components/AccordionDetails';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import { Select } from 'apollo-react';
import Pencil from 'apollo-react-icons/Pencil';
import { Collapse } from '@mui/material';
import ArrowRight from 'apollo-react-icons/ArrowRight';
import { Trash } from 'apollo-react-icons';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import MenuItem from 'apollo-react/components/MenuItem';
import PlusIcon from 'apollo-react-icons/Plus';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'Components/Common/Notification';
import CustomModal from 'Components/Modal';
import Typography from 'apollo-react/components/Typography';
import { useStyles } from './style';
import {
  SavePostSQLQueries,
  updateQueryStatus,
  deletePostSQLQueries,
  importQCodeUpdateQueries
} from 'Redux/Service/PostSQLEditorService';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { showBanner } from 'Redux/Slice/BannerSlice';
import PgSQLEditor, { transformSqlQueryToTemplate } from 'Components/PgSQLEditor/PgSQLEditor';
import Divider from 'apollo-react/components/Divider';
import Cookies from 'js-cookie';
import { useMonaco } from '@monaco-editor/react';
import useGlobalMessage from 'Utils/useGlobalMessage';
import SQLTemplateDropdown from './SQLTemplateDropdown';

let specialCharMessageError = 'Special characters are not allowed.';
let duplicateDomainMessageError = 'Domain name already exist.';
let domainLengthExceedMessageError = 'Maximum 5 characters are allowed.';
let emptyDomainMessageError = 'Domain name should have value.';

const QueryEditor = (props) => {
  const {
    dragDisableFlag,
    setDragDisableFlag,
    rowData,
    setAccordionExpandedQueries,
    accordionExpandedQueries,
    setAddFlag,
    index,
    onMoveVariableRow,
    queryName,
    domainCode,
    isQueryActive,
    queryID,
    setRowData,
    isIconEnable,
    setIsIconEnable,
    isExistingDomain,
    editRow,
    allExistingDomainCode,
    getPostSQLQueries,
    executionOrder,
    showMappedDomainCode,
    setShowMappedDomainCode,
    sqlQuery,
    queryType,
    isEditEnable,
    setLoading,
    description,
    showSqlQueryOption,
    qcodeDatasetName,
    defaultSqlTemplate,
    domainQcodeMappingObj
  } = props;

  const classes = useStyles();
  const rowref = useRef();
  const queryRef = useRef();
  const [showCustomDomainModal, setShowCustomDomainModal] = useState(false);
  const [isQueryNameDuplicate, setIsQueryNameDuplicate] = useState(false);
  const [isActive, setActive] = useState(isQueryActive);
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();
  const [isQueryDelete, setIsQueryDelete] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [draggable, setDraggable] = useState(false);
  const [dragPosition, setDragPosition] = useState('');
  const [isCancelFlag, setIsCancelFlag] = useState(false);
  const [isQueryNameLengthExceed, setIsQueryNameLengthExceed] = useState(false);
  const [isDomainNameSelected, setIsDomainNameSelected] = useState(true);
  const [isDomainvalue, setIsDomainValue] = useState({
    isDomainAlreadyExist: false,
    isEmptyDomainValue: false,
    isDomainNameLengthExceed: false,
    isSpecialCharExist: false
  });
  const { id: mappingRuleVersionID } = useParams();
  const [draftSqlQuery, setDraftSqlQuery] = useState({ sqlQuery, queryType, isValid: false });
  const [customDomain, setCustomDomain] = useState('');
  const userId = Cookies.get('user.id');

  const monaco = useMonaco();
  const { getMessage } = useGlobalMessage();

  useEffect(() => {
    const domainMapping = showMappedDomainCode?.filter((domain) => !domain.isCustomDomain);
    if (monaco && domainMapping?.length && isEditEnable) {
      const _handleCreateSuggestion = (mapping, variable, isReserved) => ({
        filterText: `${mapping.domainCode}.${variable}`,
        label: variable,
        detail: `${mapping.domainCode}.${variable}${isReserved ? ' (R)' : ''}`,
        documentation: `Table: ${mapping.domainCode}\nColumn: ${variable}${
          isReserved ? '\n(Reserved Variable)' : ''
        }`,
        kind: monaco.languages.CompletionItemKind.Property,
        insertText: variable
      });

      return monaco.languages?.registerCompletionItemProvider('pgsql', {
        provideCompletionItems: () => {
          const suggestions = [];
          domainMapping.forEach((mapping) => {
            if (isExistingDomain) {
              if (mapping?.domainCode === domainCode) {
                mapping.variableName.forEach((variable) => {
                  suggestions.push(_handleCreateSuggestion(mapping, variable));
                });
              }
            } else {
              mapping.variableName.forEach((variable) => {
                suggestions.push(_handleCreateSuggestion(mapping, variable));
              });
              mapping.reservedVariables?.forEach((variable) => {
                suggestions.push(_handleCreateSuggestion(mapping, variable, true));
              });
            }
          });

          return { suggestions };
        }
      }).dispose;
    }
  }, [monaco, showMappedDomainCode?.length, queryType, isEditEnable, domainCode, isExistingDomain]);

  const setIsEditEnable = (enable) => {
    editRow(enable, 'isEditEnable', queryID);
  };

  useEffect(() => {
    setLoaded(true);
    if (queryName?.length && domainCode?.length) {
      setIsEditEnable(false);
      setAddFlag(false);
      setIsIconEnable(false);
      setIsDomainNameSelected(false);
    }
  }, []);

  const handleDeleteModal = (event, rowID) => {
    setIsQueryDelete(true);
    const data = rowData.find((item) => item.queryID === rowID);
    setSelectedData(data);
    event.stopPropagation();
  };

  const onDelete = async (rowID) => {
    const payload = {
      mappingRuleVersionID: mappingRuleVersionID,
      queryID: rowID
    };
    const deletePostSQLResponse = await dispatch(deletePostSQLQueries(payload)).then(unwrapResult);
    if (deletePostSQLResponse?.data?.success) {
      await getPostSQLQueries();
      dispatch(showBanner({ variant: 'success', message: deletePostSQLResponse.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: deletePostSQLResponse.data.message }));
    }
    setIsQueryDelete(false);
  };

  useEffect(() => {
    if (queryName?.length && domainCode?.length) {
      if (!accordionExpandedQueries.includes(queryID)) {
        setAccordionExpandedQueries([...accordionExpandedQueries, queryID]);
      }
    } else {
      if (accordionExpandedQueries.includes(queryID)) {
        const temp = accordionExpandedQueries.filter((id) => id !== queryID);
        setAccordionExpandedQueries(temp);
      }
    }
  }, [queryName, domainCode]);

  const sqlQueryTemplateChangeHandler = async (event) => {
    const sqlQueryTemp = event.target.value;
    editRow(sqlQueryTemp, 'qcodeDatasetName', queryID);
    if (sqlQueryTemp === defaultSqlTemplate) {
      setDraftSqlQuery({ sqlQuery: '', queryType: '', isValid: false });
      return;
    }
    const payload = {
      domainCode: domainCode,
      qcodeDomain: sqlQueryTemp
    };

    setLoading(true);
    const response = await dispatch(importQCodeUpdateQueries(payload)).then(unwrapResult);
    if (response && response?.data?.success && response.data.importQCodeUpdateQueries?.length > 0) {
      const { importQCodeUpdateQueries } = response.data;
      const { datasetName, queryType, userQuery } = importQCodeUpdateQueries[0];
      setDraftSqlQuery({ sqlQuery: userQuery, queryType, isValid: true });
    } else {
      editRow('', 'description', queryID);
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
    }
    setLoading(false);
  };

  const handleEditQuery = (event, rowID) => {
    const data = rowData.find((item) => item.queryID === rowID);
    setSelectedData(data);
    setIsEditEnable(true);
    setDragDisableFlag(true);
    setIsIconEnable(true);
    setIsQueryNameLengthExceed(false);
    setIsDomainNameSelected(false);
    event.stopPropagation();
  };

  const handleValidateDomainName = (newDomainValue) => {
    let newArr = [...showMappedDomainCode];
    const regex = /[~`' +=;/!@#$%^&*(),-.?":{}|<>[\]\\]/;
    const checkSpecialChar = regex.test(newDomainValue);
    const checkDomainDataPresent = allExistingDomainCode.some(
      (domain) => domain === newDomainValue
    );
    if (checkSpecialChar) {
      setIsDomainValue({
        isDomainAlreadyExist: false,
        isEmptyDomainValue: false,
        isDomainNameLengthExceed: false,
        isSpecialCharExist: true
      });
    } else if (newDomainValue == undefined || newDomainValue === '') {
      setIsDomainValue({
        isDomainAlreadyExist: false,
        isEmptyDomainValue: true,
        isDomainNameLengthExceed: false,
        isSpecialCharExist: false
      });
    } else if (newDomainValue?.length > 5) {
      setIsDomainValue({
        isDomainAlreadyExist: false,
        isEmptyDomainValue: false,
        isDomainNameLengthExceed: true,
        isSpecialCharExist: false
      });
    } else if (
      !newArr.some((domain) => domain.domainCode === newDomainValue) &&
      !checkDomainDataPresent
    ) {
      newArr.push({
        domainCode: newDomainValue,
        isCustomDomain: true
      });
      setShowMappedDomainCode(newArr);
      editRow(customDomain, 'domainCode', queryID);
      setShowCustomDomainModal(false);
      setCustomDomain('');
    } else {
      setIsDomainValue({
        isDomainAlreadyExist: true,
        isEmptyDomainValue: false,
        isDomainNameLengthExceed: false,
        isSpecialCharExist: false
      });
      setShowCustomDomainModal(true);
    }
  };

  const handleReset = (rowID) => {
    let modifiedRowsData = [];
    rowData.forEach((_row) => {
      if (_row.queryID === rowID && _row.isSaved === true && isEditEnable)
        modifiedRowsData.push(selectedData);
      else if (_row.isSaved === false && isEditEnable) {
      } else modifiedRowsData.push(_row);
    });
    setRowData(modifiedRowsData);
    setIsCancelFlag(false);
    if (isEditEnable) {
      setIsIconEnable(false);
      setIsEditEnable(false);
    }
    setIsQueryNameDuplicate(false);
    setDraftSqlQuery({ sqlQuery, queryType });
    queryRef?.current?.resetErrorText();

    setIsQueryNameLengthExceed(false);
    if (accordionExpandedQueries.includes(queryID)) {
      const temp = accordionExpandedQueries.filter((id) => id !== queryID);
      setAccordionExpandedQueries(temp);
    }
  };

  const handleToggleChange = async (e, checked) => {
    setActive(checked);
    const payload = {
      queryID: queryID,
      isActive: checked
    };
    const updateQueryResponse = await dispatch(updateQueryStatus(payload)).then(unwrapResult);
    if (updateQueryResponse?.data?.success) {
      dispatch(showBanner({ variant: 'success', message: updateQueryResponse.data.message }));
      await getPostSQLQueries();
    } else {
      dispatch(showBanner({ variant: 'error', message: updateQueryResponse.data.message }));
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        queryID: queryID,
        queryName: queryName,
        queryType: draftSqlQuery.queryType,
        mappingRuleVersionID: mappingRuleVersionID,
        domainCode: domainCode,
        sqlQuery:
          qcodeDatasetName && qcodeDatasetName !== defaultSqlTemplate
            ? draftSqlQuery.sqlQuery
            : transformSqlQueryToTemplate(draftSqlQuery.sqlQuery),
        userQuery: draftSqlQuery.sqlQuery,
        executionOrder: !isEditEnable ? rowData.length : executionOrder,
        isExistingDomain,
        isQueryActive: isActive,
        iqAuditType: queryID.length ? 'UPDATE' : 'INSERT',
        iqAuditDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqActive_Flag: true,
        description: description,
        qcodeDatasetName:
          qcodeDatasetName && qcodeDatasetName !== defaultSqlTemplate ? qcodeDatasetName : ''
      };
      const isQueryNameAlreadyExists = rowData.some(
        (row) => row.queryID !== queryID && row.queryName === queryName && queryName !== ''
      );
      const isQueryNameLengthNotInLimit = queryName.length > 30;
      if (isQueryNameLengthNotInLimit) {
        setIsQueryNameLengthExceed(true);
        setIsQueryNameDuplicate(false);
      } else if (!isQueryNameAlreadyExists) {
        const savePostSQLResponse = await dispatch(SavePostSQLQueries(payload)).then(unwrapResult);
        if (savePostSQLResponse?.data?.success) {
          dispatch(showBanner({ variant: 'success', message: savePostSQLResponse.data.message }));
          await getPostSQLQueries();
        } else {
          dispatch(showBanner({ variant: 'error', message: savePostSQLResponse.data.message }));
        }
        setIsQueryNameDuplicate(false);
        setIsQueryNameLengthExceed(false);
        setIsDomainNameSelected(false);
        setAddFlag(false);
        setIsIconEnable(false);
        setIsEditEnable(false);
      } else {
        setIsQueryNameDuplicate(true);
        setAddFlag(true);
      }
      const temp = accordionExpandedQueries.filter((id) => id !== queryID);
      setAccordionExpandedQueries(temp);
    } catch (error) {
      console.log('Error while saving query', error);
    }
    setLoading(false);
  };

  const domainModalButtonProps = [
    {
      label: 'Cancel',
      onClick: () => {
        setShowCustomDomainModal(false);
      }
    },
    {
      label: 'Save Domain',
      onClick: () => handleValidateDomainName(customDomain)
    }
  ];

  const deleteModalButtonProps = [
    {
      label: 'Cancel',
      onClick: () => {
        setIsQueryDelete(false);
      }
    },
    {
      label: isExistingDomain ? 'Delete Query' : 'Delete',
      onClick: () => onDelete(queryID)
    }
  ];

  const handleAddNewDomain = () => {
    setShowCustomDomainModal(true);
    setIsDomainValue({
      isDomainAlreadyExist: false,
      isEmptyDomainValue: false,
      isDomainNameLengthExceed: false,
      isSpecialCharExist: false
    });
  };

  const handleOnDragOver = (e) => {
    e.dataTransfer.dropEffect = 'move';
    const {
      nativeEvent: { offsetY }
    } = e;
    const offsetHeight = rowref.current.offsetHeight;
    if (offsetY > offsetHeight / 2) {
      setDragPosition('bottom');
    } else if (offsetY < offsetHeight / 2) {
      setDragPosition('top');
    }
    e.preventDefault();
  };

  const handleOnDragEnd = (e) => {
    setDraggable(false);
    setDragPosition('');
    const {
      target: {
        parentElement: { childNodes },
        offsetTop,
        offsetHeight
      },
      nativeEvent: { offsetY }
    } = e;
    const currentRowPosition = offsetTop + offsetY;
    const rowPositions = [];
    for (let child of childNodes) {
      rowPositions.push(child.offsetTop + child.offsetHeight / 2);
      child.classList.remove(classes.dragTopRow, classes.dragBottomRow);
      child.classList.add(classes.collapse);
    }
    let moveToIndex =
      offsetY > offsetHeight / 2
        ? rowPositions.reduce((previousIndex, rowPosition, rowIndex) => {
            if (currentRowPosition > rowPosition) {
              return rowIndex;
            }
            return previousIndex;
          }, index)
        : rowPositions.reduceRight((previousIndex, rowPosition, rowIndex) => {
            if (currentRowPosition < rowPosition) {
              return rowIndex;
            }
            return previousIndex;
          }, index);

    if (index !== moveToIndex) onMoveVariableRow(index, moveToIndex);
    e.target.children[0].children[0].firstChild.style.border = '2px solid #0768fd';
    e.target.children[0].children[0].firstChild.style.borderBottomLeftRadius = '0';
    e.target.children[0].children[0].firstChild.style.borderBottomRightRadius = '0';
    setTimeout(() => {
      e.target.children[0].children[0].firstChild.style.opacity = 1;
      e.target.children[0].children[0].firstChild.style.border = '';
      e.target.children[0].children[0].firstChild.style.borderBottomLeftRadius = '';
      e.target.children[0].children[0].firstChild.style.borderBottomRightRadius = '';
    }, 600);
  };

  const handleAccodionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) setAccordionExpandedQueries([...accordionExpandedQueries, panel]);
    else {
      const temp = accordionExpandedQueries.filter((id) => id !== queryID);
      setAccordionExpandedQueries(temp);
    }
  };

  const handleRunValidations = () => {
    const result = queryRef?.current?.validateQuery(
      showMappedDomainCode?.filter((domain) => !domain.isCustomDomain),
      isExistingDomain
    );
    if (result?.isValid) {
      setDraftSqlQuery(result);
      editRow(false, 'hasError', queryID);
    } else {
      setAccordionExpandedQueries([...accordionExpandedQueries, queryID]);
      editRow(true, 'hasError', queryID);
    }
  };

  useEffect(() => {
    if (showMappedDomainCode?.length && sqlQuery) {
      !qcodeDatasetName && handleRunValidations();
    }
  }, [showMappedDomainCode?.length]);

  const queryLabel = () => {
    if (isEditEnable) return 'Add Description (Optional)';
    else
      return <Typography style={{ fontSize: '14px', fontWeight: '600' }}>Description</Typography>;
  };

  return (
    <>
      <Collapse
        in={loaded}
        draggable={draggable}
        ref={rowref}
        className={
          dragPosition === 'bottom'
            ? classes.dragBottomRow
            : dragPosition === 'top'
            ? classes.dragTopRow
            : classes.collapse
        }
        onDragEnd={handleOnDragEnd}
        onDragStart={() => setAccordionExpandedQueries([])}
        onDragLeave={() => setDragPosition('')}
        onDragOver={(e) => handleOnDragOver(e)}>
        <Accordion
          className={classes.filterType}
          expanded={accordionExpandedQueries.includes(queryID)}
          onChange={handleAccodionChange(queryID)}
          data-testid="query-accordian">
          <AccordionSummary
            className={
              isQueryNameDuplicate || isQueryNameLengthExceed
                ? classes.accordianSummaryHeight
                : classes.accordianSummary
            }
            expandIcon={<ExpandMoreIcon />}>
            <div className={classes.filterTypeHeader}>
              <div className={classes.filterTypeEntryFields}>
                <span className={classes.filterTypeTitle}>{`${props.executionOrder}.`}</span>
                <div className={classes.queryContainer} onClick={(e) => e.stopPropagation()}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'240px'}
                    style={
                      !isEditEnable ||
                      (!isDomainNameSelected && (isQueryNameDuplicate || isQueryNameLengthExceed))
                        ? { marginRight: '16px', position: 'relative' }
                        : { margin: '0px 16px 0px 0px' }
                    }>
                    {isEditEnable ? (
                      <TextField
                        label="Query Name"
                        placeholder={'Enter Query Name'}
                        sx={{ marginTop: '0.5rem' }}
                        size={'small'}
                        value={queryName}
                        className={
                          (isQueryNameDuplicate || isQueryNameLengthExceed) &&
                          classes.inlineErrorContainer
                        }
                        onChange={(e) => editRow(e.target.value, 'queryName', queryID)}
                        helperText={
                          (isQueryNameDuplicate && 'Query name should be unique.') ||
                          (isQueryNameLengthExceed && 'Maximum 30 characters are allowed.')
                        }
                        error={isQueryNameDuplicate || isQueryNameLengthExceed}></TextField>
                    ) : (
                      <span>
                        <Typography className={classes.textFieldContainer}>Query Name</Typography>
                        <Typography className={classes.textFieldContainer1}>{queryName}</Typography>
                      </span>
                    )}
                  </Box>
                  <Box
                    style={
                      !isEditEnable ||
                      (!isDomainNameSelected && (isQueryNameDuplicate || isQueryNameLengthExceed))
                        ? { marginRight: '16px', position: 'relative' }
                        : {
                            display: 'flex',
                            flexDirection: 'column'
                          }
                    }>
                    {isDomainNameSelected ? (
                      <Grid container item xs={12} spacing={2}>
                        <Grid xs={6} item>
                          <Select
                            data-testid="domain-select"
                            label="Domain"
                            placeholder={'Select domain to apply'}
                            className={classes.fieldElement}
                            size={'small'}
                            sx={{
                              marginTop: '0.5rem',
                              marginBotton:
                                (isQueryNameDuplicate || isQueryNameLengthExceed) && '18px'
                            }}
                            fullWidth
                            onChange={(e) => {
                              setDraftSqlQuery({ sqlQuery: '', queryType: '', isValid: false });
                              editRow(defaultSqlTemplate, 'qcodeDatasetName', queryID);
                              editRow(e.target.value, 'domainCode', queryID);                            
                              return;
                            }}
                            value={domainCode}>
                            {showMappedDomainCode
                              .filter(
                                (item) => !item.isCustomDomain || item.domainCode === domainCode
                              )
                              .map((item) => (
                                <MenuItem
                                  value={item.domainCode}
                                  disabled={
                                    item.isCustomDomain &&
                                    rowData.some((row) =>
                                      Object.values(row).includes(item.domainCode)
                                    )
                                  }>
                                  {item.isCustomDomain ? (
                                    <div>
                                      <span>{`${item.domainCode}`}</span>
                                      <span className={classes.customDomainChip}>Custom</span>
                                    </div>
                                  ) : (
                                    <span>{item.domainCode}</span>
                                  )}
                                </MenuItem>
                              ))}

                            <span className={classes.addNewDomainButtonContainer}>
                              <Button icon={<PlusIcon />} onClick={handleAddNewDomain} fullWidth>
                                Add New Domain
                              </Button>
                            </span>
                          </Select>
                        </Grid>

                        {domainCode && domainQcodeMappingObj[domainCode] && (
                          <Grid xs={6} item>
                            <SQLTemplateDropdown
                              classes={classes}
                              isQueryNameDuplicate={isQueryNameDuplicate}
                              isQueryNameLengthExceed={isQueryNameLengthExceed}
                              qcodeDatasetName={qcodeDatasetName}
                              sqlQueryTemplateChangeHandler={sqlQueryTemplateChangeHandler}
                              showSqlQueryOption={showSqlQueryOption}
                            />
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid container item xs={12} spacing={2}>
                        <Grid xs={6} item>
                          <div className={classes.fieldElement}>
                            <Typography className={classes.textFieldContainer}>Domain</Typography>
                            {domainCode && (
                              <div>
                                <Typography className={classes.textFieldContainer1}>
                                  {`${domainCode}`}
                                </Typography>
                                {!isExistingDomain && (
                                  <span className={classes.customDomainChip}>Custom</span>
                                )}
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid xs={6} item>
                          <div className={classes.fieldElement}>
                            {qcodeDatasetName && (
                              <div>
                                <Typography className={classes.textFieldContainer}>
                                  SQL Template
                                </Typography>
                                <Typography className={classes.textFieldContainer1}>
                                  {`${getMessage(qcodeDatasetName) }`}
                                </Typography>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    {showCustomDomainModal && (
                      <CustomModal
                        display={showCustomDomainModal}
                        title={'Add New Domain'}
                        message={
                          <>
                            <Typography>Domain Name</Typography>
                            <TextField
                              value={customDomain}
                              onChange={(e) => setCustomDomain(e.target.value)}
                              style={{ whiteSpace: 'pre-line', marginTop: '0px' }}
                              fullWidth
                              helperText={
                                isDomainvalue.isDomainAlreadyExist
                                  ? duplicateDomainMessageError
                                  : isDomainvalue.isSpecialCharExist
                                  ? specialCharMessageError
                                  : isDomainvalue.isDomainNameLengthExceed
                                  ? domainLengthExceedMessageError
                                  : isDomainvalue.isEmptyDomainValue && emptyDomainMessageError
                              }
                              error={Object.values(isDomainvalue).some((el) => el === true)}
                            />
                            <Notification
                              variant={'warning'}
                              message={'Once saved the domain name cannot changed.'}
                              style={{ marginTop: '2px' }}
                            />
                          </>
                        }
                        buttonProps={domainModalButtonProps}
                        handleClose={() => {
                          setShowCustomDomainModal(false);
                        }}
                        width={classes.modal}
                      />
                    )}
                  </Box>
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <div className={`tooltip`} style={{ margin: '6px 15px 0 8px' }}>
                  <Pencil
                    style={{
                      cursor: isIconEnable ? 'auto' : 'pointer',
                      color: isIconEnable ? '#B5B5B5' : '#595959'
                    }}
                    disabled={isIconEnable}
                    onClick={(e) =>
                      !isIconEnable && handleAccodionChange() && handleEditQuery(e, queryID)
                    }
                    data-testid="pencil-icon"
                  />
                  <span className="tooltiptext4 tooltip-bottom4">{'Edit'}</span>
                </div>
                <div className={`tooltip`} style={{ margin: '6px 22px 0 8px' }}>
                  <Trash
                    style={{
                      cursor: isIconEnable ? 'auto' : 'pointer',
                      color: isIconEnable ? '#B5B5B5' : '#595959'
                    }}
                    onClick={(e) => !isIconEnable && handleDeleteModal(e, queryID)}
                    data-testid="trash-icon"
                  />
                  <span className="tooltiptext4 tooltip-bottom4">{'Delete'}</span>
                </div>
                <span>Active</span>
                <Switch
                  size="small"
                  className={classes.switch}
                  checked={isActive}
                  onChange={handleToggleChange}
                  onClick={(e) => e.stopPropagation()}
                  data-testid="active-toggle"
                />
                <div className={`tooltip`}>
                  <Drag
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={() => !isEditEnable && !dragDisableFlag && setDraggable(true)}
                    onMouseUp={() => !isEditEnable && !dragDisableFlag && setDraggable(false)}
                    style={{
                      cursor:
                        isEditEnable || dragDisableFlag ? 'auto' : draggable ? 'grabbing' : 'grab',
                      color: dragDisableFlag ? '#B5B5B5' : '#595959',
                      margin: '6px 0 0 0'
                    }}
                  />
                  <span className="tooltiptext4 tooltip-bottom4">
                    {dragDisableFlag ? 'Save all the queries to Drag' : 'Drag'}
                  </span>
                </div>
              </div>
            </div>
          </AccordionSummary>
          {domainCode?.length && isEditEnable && (
            <Notification
              variant={'info'}
              message={
                showMappedDomainCode.find(
                  (item) => item.domainCode === domainCode && item.isCustomDomain
                ) ? (
                  <>
                    For custom domains only <span style={{ fontWeight: '600' }}>SELECT </span>
                    statements are allowed
                  </>
                ) : (
                  <>
                    For default domain only <span style={{ fontWeight: '600' }}>UPDATE </span>
                    statements are allowed
                  </>
                )
              }
              style={{ margin: '0px' }}
            />
          )}
          <AccordionDetails className={classes.filterExpressionContainer}>
            <PgSQLEditor
              readOnly={
                !isEditEnable || !qcodeDatasetName || qcodeDatasetName !== defaultSqlTemplate
              }
              domainCode={domainCode}
              ref={queryRef}
              sqlQuery={draftSqlQuery?.sqlQuery}
              queryType={draftSqlQuery?.queryType}
              onQueryChange={(_sqlQuery) =>
                setDraftSqlQuery((_draftSqlQuery) => ({
                  ...draftSqlQuery,
                  sqlQuery: _sqlQuery,
                  isValid: false
                }))
              }
            />
            <Divider />
            {(!isEditEnable && domainCode?.length > 0) || isEditEnable ? (
              <>
                <TextField
                  multiline
                  minRows={1}
                  InputProps={{
                    readOnly: qcodeDatasetName !== defaultSqlTemplate || !isEditEnable,
                    inputProps: {
                      maxLength: 500,
                      style: {
                        padding: '0px',
                        border: 'none',
                        fontSize: '14px'
                      }
                    }
                  }}
                  helperText={
                    description.length === 500 ? 'Maximum 500 characters are allowed' : null
                  }
                  value={description}
                  onChange={(e) => editRow(e.target.value, 'description', queryID)}
                  style={{
                    padding: '0px 15px',
                    margin: '8px 0px'
                  }}
                  label={queryLabel()}
                  placeholder="Enter Text"
                />
                <Divider />
              </>
            ) : null}
            {isEditEnable && (
              <Grid
                item
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: '0.5rem'
                }}>
                <Grid
                  style={{
                    display: 'flex'
                  }}>
                  <Button
                    variant="secondary"
                    icon={<ArrowRight />}
                    size="small"
                    onClick={handleRunValidations}
                    disabled={
                      (qcodeDatasetName && qcodeDatasetName !== defaultSqlTemplate) ||
                      draftSqlQuery?.isValid
                    }
                    data-testid="validate-btn">
                    Validate
                  </Button>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}>
                  <Button
                    sx={{ marginRight: '8px' }}
                    variant="secondary"
                    size="small"
                    onClick={() => setIsCancelFlag(true)}>
                    Cancel
                  </Button>
                  <Button
                    variant="secondary"
                    icon={<Save />}
                    size="small"
                    disabled={
                      qcodeDatasetName && qcodeDatasetName !== defaultSqlTemplate
                        ? !queryName || !domainCode
                        : !queryName || !domainCode || !draftSqlQuery?.isValid
                    }
                    onClick={handleSave}>
                    Save Query
                  </Button>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
        <Box
          flexDirection={'column'}
          style={{
            width: '50%'
          }}>
          <Box
            style={{
              borderRight: '1px solid lightgrey',
              height: '32px',
              marginLeft: '10px'
            }}></Box>
        </Box>
      </Collapse>
      {isQueryDelete && (
        <CustomModal
          variant="error"
          display={isQueryDelete}
          data={selectedData}
          title={
            <>
              <Typography style={{ color: '#E20000', fontSize: '20px', fontWeight: '600' }}>
                {isExistingDomain ? 'Delete Query' : 'Delete Custom Domain and Query'}
              </Typography>
              {
                <Typography
                  style={{
                    fontSize: '13px',
                    fontWeight: '600'
                  }}>{`Query Name : ${selectedData?.queryName}`}</Typography>
              }
              {
                <Typography
                  style={{
                    fontSize: '13px',
                    fontWeight: '600'
                  }}>{`Domain : ${selectedData?.domainCode}`}</Typography>
              }
            </>
          }
          message={
            <>
              {isExistingDomain ? (
                <Typography>You are about to delete the query.</Typography>
              ) : (
                <Typography>
                  You are about to delete the query and the custom domain associated with it. Once
                  you delete, this action cannot be undone.
                </Typography>
              )}
            </>
          }
          buttonProps={deleteModalButtonProps}
          width={classes.modal}
        />
      )}
      {isCancelFlag && (
        <CustomModal
          display={isCancelFlag}
          title={'Warning'}
          variant="warning"
          buttonPrimaryLabel={'Ok'}
          message={
            'Changes will not be saved if you Cancel the editing text. Would you like to continue?'
          }
          data-testid="custom-modal"
          handlePrimaryAction={() => handleReset(queryID)}
          buttonSecondardyLabel={'Cancel'}
          handleClose={() => setIsCancelFlag(false)}
        />
      )}
    </>
  );
};

export default React.memo(QueryEditor);
