import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import { nonEcrfStyle } from './NonECRSFStyle';
import { makeStyles } from '@mui/styles';
import { LIBRARY_TYPES } from 'Constants/LibraryTypes';
import {
  setDescription,
  setIsRunAllExecuted,
  setNextEnabled,
  setSelectedTrialName
} from 'Redux/Slice/SDHDataProductSlice';
import { useDispatch, useSelector } from 'react-redux';
import useDisplayName from 'Utils/useDisplayName';

function AddNonECRFForm({ edcTrialOptions, formik }) {
  const useStyles = makeStyles(nonEcrfStyle.nonEcrfForm);
  const classes = useStyles();
  const dispatch = useDispatch();
  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);
  const {
    productMnemonic,
    libraryName,
  } = sdhDataProduct

  const getDisplayName = useDisplayName()

  const onTrialChangeHandler =(e)=> {
    const selectedTrial = edcTrialOptions.find(item => item.edcTrialName === e.target.value);
    dispatch(setNextEnabled(false));
    dispatch(setSelectedTrialName(selectedTrial))
    dispatch(setIsRunAllExecuted(false));
    return formik?.handleChange(e);
  }
  
  return (
    <>
      <Box mt={2}>
        <Grid container spacing={0} mt={1}>
          <Grid item xs={2}>
            <Typography variant="body2" className={classes.label}>
              Product Mnemonic
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {productMnemonic}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" className={classes.label}>
              Library Name
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {libraryName}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="body2" className={classes.label}>
              Data Source
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {/* {LIBRARY_TYPES.EDC_CODS} */}
              {getDisplayName(LIBRARY_TYPES.CDISC_ODM)}
              {/* CDISC_ODM */}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Grid xs={5}>
          <Select
            data-testid="trialName"
            name="trialName"
            id="trialName"
            label="Trial Name/Environment Name"
            fullWidth
            canDeselect={false}
            value={formik?.values?.trialName}
            onChange={(e) => onTrialChangeHandler(e)}
            placeholder="Select">
            {edcTrialOptions?.map((item) => (
              <MenuItem key={item.edcTrialName} value={item.edcTrialName}>
                {item.edcTrialName}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={5}>
          <TextField
            id="description"
            label="Description (Optional)"
            name="description"
            multiline
            placeholder="Some Description"
            fullWidth
            value={formik.values.description}
            onChange={(e) => {
              dispatch(setDescription(e.target.value));
              return formik.handleChange(e);
            }}
          />
        </Grid>
      </Box>
    </>
  );
}

export default AddNonECRFForm;
