import React, { forwardRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';

import Accordion from 'apollo-react/components/Accordion';
import AccordionDetails from 'apollo-react/components/AccordionDetails';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
import Grid from 'apollo-react/components/Grid';
import { LIBRARY_TYPES } from 'Constants/LibraryTypes';
import useDisplayName from 'Utils/useDisplayName';

const useStyles = makeStyles({
  libraryType: {
    width: '100%',
    marginBottom: '1rem !important',
    boxShadow: '0px 4px 16px rgba(0,0,0,0.04)',
    border: '1px solid #E9E9E9',
    borderRadius: '4px'
  },
  libraryTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '48px'
  },
  libraryTypeTitle: {
    fontSize: '14px',
    fontWeight: '500'
  },
  expressionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #F6F7FB',
    padding: '12px 32px',
    '& *': {
      fontSize: '14px !important'
    }
  },
});

const PreviewCreate = () => {
  const dispatch = useDispatch();
  const {workFlowModel } = useSelector(state=> state.SDHDataProduct);

  const classes = useStyles();
  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title: 'Create SDH Data Product',
          path: `/product-designer/${workFlowModel[1]?.moduleName}`
        }
      ])
    );
  }, []);

  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);
  const {
      selectedTrialName,
      productMnemonic,
      libraryName,
      targetLibrary,
      nonEcrfDatasets,
  } = sdhDataProduct;

  const getDisplayName = useDisplayName()
  
  const datasetNames = nonEcrfDatasets?.length > 0 ? nonEcrfDatasets?.map(item => item.datasetName).join(', ') : '';
  
  return (
    <>
      <Grid p={2} style={{minHeight: '70vh'}}>
        <Typography variant="h3" mb={2}>
          Preview and Create
        </Typography>
        <div>
          <Accordion defaultExpanded className={classes.libraryType}>
            <AccordionSummary>
              <Box className={classes.libraryTypeHeader}>
                <Typography className={`${classes.libraryTypeTitle}`}>
                  Non-eCRF Data Product
                </Typography>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>{}</Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.expressionContainer}>
              <Grid container spacing={0} mt={1} gap={2}>
                <Grid item xs={2}>
                  <Typography variant="body2" className={classes.label}>
                    Product Mnemonic:
                  </Typography>
                  <Typography variant="title" className={classes.labelDesc}>
                    {productMnemonic}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" className={classes.label}>
                    Library Name:
                  </Typography>
                  <Typography variant="title" className={classes.labelDesc}>
                    {libraryName}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body2" className={classes.label}>
                    Data Source:
                  </Typography>
                  <Typography variant="title" className={classes.labelDesc}>
                    {getDisplayName(LIBRARY_TYPES.CDISC_ODM)}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body2" className={classes.label}>
                    Trial Name/Environment Name:
                  </Typography>
                  <Typography variant="title" className={classes.labelDesc}>
                    {selectedTrialName?.edcTrialName}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body2" className={classes.label}>
                    Datasets:
                  </Typography>
                  <Typography variant="title" className={classes.labelDesc}>
                   {datasetNames}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" className={classes.label}>
                    Target Library:
                  </Typography>
                  <Typography variant="title" className={classes.labelDesc}>
                    {targetLibrary.libraryName}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
    </>
  );
};

const SDHPreviewCreate = forwardRef(PreviewCreate);
export default SDHPreviewCreate;
