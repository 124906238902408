import { Box, Grid } from '@mui/material';
import { getAllFormsAndItems, getHtmlString } from 'Utils';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import Button from 'apollo-react/components/Button';
import ClipboardSearch from 'apollo-react-icons/ClipboardSearch';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  row: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderBottom: '1px solid #4441',
    fontSize: '14px'
  },
  column: {
    padding: '1rem'
  },
  domainVariable: { fontWeight: '600' },
  expressionCol: {
    fontWeight: 'bold',
    fontFamily: 'Courier New',
    '&:not(:last-child)': {
      marginBottom: '10px'
    }
  },
  viewCodelist: {
    width: 'fit-content',
    marginTop: '1rem'
  }
});

const VariableRow = (props) => {
  const { variable = '', expressions = [], isRulesetVariable, setCodelistEditorDisplay } = props;
  const classes = useStyles();

  const handleViewCodelist = () => {
    setCodelistEditorDisplay({
      display: true,
      formItems: expressions.map((expRow) => getAllFormsAndItems(expRow.expression)).flat()
    });
  };

  return (
    <Grid container className={classes.row}>
      <Grid xs={3} className={[classes.column, classes.domainVariable]}>
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          {variable}
          {isRulesetVariable ? (
            <Button
              variant="secondary"
              onClick={handleViewCodelist}
              size={'small'}
              className={classes.viewCodelist}
              data-testid="save"
              icon={ClipboardSearch}>
              View Codelist
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Grid>

      <Grid xs={9} className={[classes.column]}>
        {expressions?.map((expRow) => (
          <Box className={classes.expressionCol} key={expRow.id}>
            <CustomeTextField
              id="expression"
              name="expression"
              data-testid="expression-input"
              disabled={true}
              customProps={{
                style: {
                  height: '100%',
                  backgroundColor: '#F2F2F2',
                  border: '1px solid #D9D9D9',
                  borderRadius: '4px',
                  whiteSpace: 'pre-line',
                  marginBottom: '0px'
                },
                placeholder: 'abc'
              }}
              error={expRow?.error}
              helperText={expRow?.error ? expRow?.errorMessage : null}>
              {getHtmlString(`${expRow.expression}`, [], '', 3)}
            </CustomeTextField>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default VariableRow;
