/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetPostSQLQueries = createAsyncThunk(
    'RuleEditor/GetPostSQLQueries',
    async (mappingRuleVersionID) => {
      return await axios.get(
        `${API_URL}/api/RuleEditor/GetPostSQLQueries?MappingRuleVersionID=${mappingRuleVersionID}`
      );
    }
);

export const SavePostSQLQueries = createAsyncThunk(
    'RuleEditor/SavePostSQLQueries',
    async (payload) => {
      return await axios.post(
        `${API_URL}/api/RuleEditor/SavePostSQLQueries`, payload
      );
    }
);

export const updateQueryStatus = createAsyncThunk(
    'RuleEditor/UpdateQueryStatus',
    async (payload) => {
      return await axios.put(
        `${API_URL}/api/RuleEditor/UpdateQueryStatus?QueryID=${payload.queryID}&isActive=${payload.isActive}`
      );
    }
);

export const deletePostSQLQueries = createAsyncThunk(
    'RuleEditor/DeletePostSQLQueries',
    async (payload) => {
      return await axios.put(
        `${API_URL}/api/RuleEditor/DeletePostSQLQueries?MappingRuleVersionID=${payload.mappingRuleVersionID}&QueryID=${payload.queryID}`
      );
    }
);

export const ReOrderPostSQLQueries = createAsyncThunk(
  'RuleEditor/ReOrderPostSQLQueries',
  async (payload) => {
    return await axios.post(
      `${API_URL}/api/RuleEditor/ReOrderPostSQLQueries`, payload
    );
  }
);

  




export const getDomainAndVariables = createAsyncThunk(
    'RuleEditor/GetDomainAndVariables',
    async (mappingRuleVersionID) => {
      const response = await axios.get(
        `${API_URL}/api/RuleEditor/GetDomainAndVariables?MappingRuleVersionID=${mappingRuleVersionID}`
      );
      return response;
    }
);

export const importQCodeUpdateQueries = createAsyncThunk(
  'RuleEditor/ImportQCodeUpdateQueries',
  async (payload) => {
    const response = await axios.get(
      `${API_URL}/api/RuleEditor/ImportQCodeUpdateQueries/?domainCode=${payload.domainCode}&qcodeDomain=${payload.qcodeDomain}`,
    );
    return response;
    
  }
);