import React, { useState, useEffect } from 'react';
import Table from 'apollo-react/components/Table';
import Button from 'apollo-react/components/Button';
import FilterIcon from 'apollo-react-icons/Filter';
import { makeStyles } from '@mui/styles';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { compareStrings, createStringSearchFilter } from 'apollo-react/components/Table';
import { TextFieldFilter, IntegerFilter } from 'Pages/Dashboard/Components/Filters';
import { numberSearchFilter } from 'Pages/Dashboard/DataProductSummary/Components/columns';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Tooltip from 'apollo-react/components/Tooltip';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 0'
  },
  content: {
    minWidth: '10%'
  },

  header: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '24px'
  },
  value: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '600'
  },
  dataSetValue: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '600',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  vlcValue: {
    color: '#444444',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500'
  },
  contentCount: {
    minWidth: '10%',
    paddingLeft: '1.5rem'
  }
});

const CustomButtonHeader = ({ toggleFilters }) => {
  return (
    <div>
      <Button
        size="small"
        variant="secondary"
        icon={FilterIcon}
        style={{ marginRight: 15 }}
        onClick={toggleFilters}>
        Filter
      </Button>
    </div>
  );
};

const statusCell = ({ row }) => {
  if (row?.isSuccess) {
    return <Typography style={{ color: '#028217' }}>{row?.status}</Typography>;
  } else {
    return <Typography style={{ color: '#BD0100' }}>{row?.status}</Typography>;
  }
};

const columns = [
  {
    header: 'VLC Rule Name',
    accessor: 'vlcRuleName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('vlcRuleName')
  },
  {
    header: 'Issue Count',
    accessor: 'issueCount',
    filterComponent: IntegerFilter,
    filterFunction: numberSearchFilter('issueCount'),
  },
  {
    header: 'VLC Execution Status',
    accessor: 'status',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('status'),
    customCell: statusCell
  }
];

const VLCRulesDataTable = ({ vlcStats, dataSetsRowVal }) => {
  const classes = useStyles();
  const [totalIssueCount, setTotalIssueCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    const calculateCounts = () => {
      let totalIssues = 0;
      let success = 0;
      let error = 0;

      vlcStats?.forEach((item) => {
        totalIssues += item.issueCount;
        if (item.isSuccess) {
          success += 1;
        } else {
          error += 1;
        }
      });

      setTotalIssueCount(totalIssues);
      setSuccessCount(success);
      setErrorCount(error);
    };

    calculateCounts();
  }, [vlcStats?.length]);

  return (
    <>
      <Grid item xs={12} className={classes.container}>
        <Grid item xs={2} className={classes.content}>
          <Typography className={classes.header}>Job Run ID</Typography>
          <Typography className={classes.value}>{dataSetsRowVal?.jobRunID}</Typography>
        </Grid>

        <Grid item xs={3} className={classes.content}>
          <Typography className={classes.header}>Data Set Name</Typography>
          <Tooltip title={dataSetsRowVal?.datasetName} placement="bottom">
            <Typography className={classes.dataSetValue}>{dataSetsRowVal?.datasetName}</Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={2} className={classes.content}>
          <Typography className={classes.header}>Data Set Type</Typography>
          <Typography className={classes.value}>{dataSetsRowVal?.datasetType}</Typography>
        </Grid>

        <Grid item xs={3} className={classes.content}>
          <Typography className={classes.header}>VLC Rules</Typography>
          <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              className={classes.vlcValue}>{`Total: ${dataSetsRowVal?.vlcRulesTotalCount}`}</Typography>
            <Typography className={classes.vlcValue}>{'|'}</Typography>
            <Typography
              className={classes.vlcValue}
              style={{ color: '#028217' }}>{`Successful: ${successCount}`}</Typography>
            <Typography className={classes.vlcValue}>{'|'}</Typography>
            <Typography
              className={classes.vlcValue}
              style={{ color: '#BD0100' }}>{`Failed: ${errorCount} `}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={2} className={classes.contentCount}>
          <Typography className={classes.header}>Total Issue Count</Typography>
          <Typography className={classes.value}>{totalIssueCount}</Typography>
        </Grid>
      </Grid>
      <Table
        classes={classes}
        columns={columns}
        rows={vlcStats}
        rowId="dataSetName"
        initialSortedColumn="vlcRuleName"
        initialSortOrder="desc"
        defaultRowsPerPage={10}
        rowsPerPageOptions={[10, 50, 100, 'All']}
        hasScroll
        CustomHeader={(prop) => <CustomButtonHeader {...prop} />}
        maxHeight={250}
        tablePaginationProps={{
          labelDisplayedRows: DisplayedRowsLabel,
          truncate: true
        }}
      />
    </>
  );
};

export default VLCRulesDataTable;
