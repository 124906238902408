/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { GetUserProfileHTML } from 'Redux/Service/AppService';
import { Box } from 'apollo-react';
import DOMPurify from 'dompurify';
import Cookies from 'js-cookie';
import moment from 'moment';

const ProfileMenu = ({ logout, isActive }) => {
  const [htmlContent, setHtml] = useState('');

  const fetchUserProfileHtml = async () => {
    const userProfileHtmlResp = await GetUserProfileHTML();
    const allowedDomains = 'iqvia.com';
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if ('onclick' in node) {
        const urlClick = node?.getAttribute('onclick');
        if (urlClick) {
          let splitArray = urlClick?.split('=');
          if (!splitArray[1]?.includes(allowedDomains)) {
            node.removeAttribute('onclick');
          }
        }
      }
    });

    const staniziedOptions = {
      ADD_ATTR: ['onclick'],
      FORCE_BODY: true
    };
    const cleanUserProfileHtmlResp = DOMPurify.sanitize(userProfileHtmlResp, staniziedOptions);
    setHtml(cleanUserProfileHtmlResp);
  };

  useEffect(() => {
    fetchUserProfileHtml();
  }, []);

  const domConentUpdate = (element) => {
    const documentElement = element;

    if (documentElement) {
      documentElement.innerHTML = element.innerHTML;

      const email = documentElement?.querySelector('.email');
      const lastlogin = documentElement?.querySelector('.lastlogin');
      if (email !== null) {
        const emailFromCookie = Cookies.get('user.email');
        email.innerHTML = emailFromCookie;
      }
      let lastLoginTime;
      if (lastlogin != null) {
        if (lastLoginTime === undefined && moment(lastlogin?.innerHTML).isValid()) {
          lastLoginTime = moment(lastlogin?.innerHTML).format('DD MMM, YYYY\xa0\xa0 hh:mm A');
          lastlogin.innerHTML = `Last login: ${lastLoginTime ? lastLoginTime : ''}`;
        }
        if (lastlogin?.innerHTML === '') {
          lastlogin.innerHTML = `Last login: ${lastLoginTime ? lastLoginTime : ''}`;
        }
      }

      const logoutButton = documentElement?.querySelector('.logout-button');
      logoutButton?.addEventListener('click', function () {
        logout();
      });

      const profilePage = documentElement?.querySelector('.profile');
      profilePage?.addEventListener('click', function () {
        // history.push();
        window.open(
          process.env.REACT_APP_CORE_URL + profilePage.getAttribute('value'),
          '_blank',
          'noopener'
        );
      });

      const dropdownDiv = documentElement?.querySelector('.dropdown');
      const header = document?.getElementsByClassName('MuiAppBar-root')?.[0];
      const dropdownButton = documentElement?.querySelector('.dropbtn');
      const profileMenu = dropdownDiv?.querySelector('.dropdown-content');
      dropdownButton?.addEventListener('click', function () {
        if (profileMenu && profileMenu?.style?.display === 'block') {
          profileMenu['style']['display'] = 'none';
          dropdownButton?.blur();
          if (header) {
            header.style.zIndex = 3000;
          }
        } else if (
          profileMenu?.style?.display === 'none' ||
          profileMenu?.style?.display === '' ||
          profileMenu?.style?.display === undefined
        ) {
          if (header) {
            header.style.zIndex = 4999;
          }
          profileMenu['style']['display'] = 'block';
        }
      });

      window.onclick = function (e) {
        if (!e.target.matches('.dropdown, .dropbtn, #fullname, .arrowdown')) {
          if (profileMenu && profileMenu?.style?.display === 'block') {
            profileMenu['style']['display'] = 'none';
            if (header) {
              header.style.zIndex = 3000;
            }
          }
        }
      };

      // if (dropdownButton && isActive) {
      //   dropdownButton['style']['background-color'] = 'rgba(255, 255, 255, 0.24)';
      // } else {
      //   dropdownButton?.removeAttribute('style');
      // }
    }
  };

  return (
    <Box
      sx={{
        '& .dropbtn:focus, .dropbtn:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.24)'
        }
      }}
      ref={(el) => {
        domConentUpdate(el);
      }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}></Box>
  );
};

export default ProfileMenu;
