/*eslint-disable */
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import Cog from 'apollo-react-icons/Cog';
import {
  default as EllipsisVertical,
  default as EllipsisVerticalIcon
} from 'apollo-react-icons/EllipsisVertical';
import Refresh from 'apollo-react-icons/Refresh';
import { neutral7, neutral8 } from 'apollo-react/colors';
import Button from 'apollo-react/components/Button';
import Checkbox from 'apollo-react/components/Checkbox';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import Switch from 'apollo-react/components/Switch';
import {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import Table from 'apollo-react/components/Table/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { saveAddStudyLibrary } from 'Redux/Service/AddStudyLibraryService';
import { studyAuditHistory } from 'Redux/Service/ModalService';
import { getData } from 'Redux/Service/PreviewService';
import { getStudyLibraryGrid, getProtocolAuth } from 'Redux/Service/StudyLibraryGridService';
import { clearStudyLibrarySources, getStudyFormObject } from 'Redux/Slice/AddStudyLibrarySlice';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import { setEditStudyLibraryData } from 'Redux/Slice/EditStudyLibrarySlice';
import {
  getCheckboxFlag,
  getDisableCheck,
  getPreviewData,
  getUpdatedStudyLibraries,
  openStudyPanel
} from 'Redux/Slice/StudyLibrarySlice';
import {
  dateFormatByType,
  jsonToExcelStudyLibrary,
  dateFilter,
  compareDates,
  jsonToExcel,
  uuidv4
} from 'Utils';
import Loader from '../../../../Components/Loader/index';
import CustomModal from '../../../../Components/Modal';
import PreviewTabs from '../../DataStandardLibraryMain/Components/PreviewTabsRow';
import PreviewTable from '../../DataStandardLibraryMain/PreviewStudyTableRow';
import StudyAuditHistory from './StudyAuditHistory';
import Filter from 'apollo-react-icons/Filter';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import BookLibrary from 'Images/book-library.svg';
import { hasNoAccess, setProtocolPermissions } from 'service/AppPermissions';
import { showLoader, closeLoader } from 'Redux/Slice/LoaderSlice';
import { DateFilter } from 'Components/Common/DateFilter';
import RuleSetEditor from 'Components/RuleSet/RuleSetEditor';
import { getVariableRuleSets } from 'Redux/Service/AddGlobalLibraryService';
import Box from 'apollo-react/components/Box';
import styled from 'styled-components';
import TableWrapper from 'Components/Common/TableWrapper';
import PreSQLPreviewTable from 'Pages/DataStandardLibrary/DataStandardLibraryMain/Components/PreSQLNewDataset/PreSQLPreviewTable';
import { getPreSQLQueries } from 'Redux/Service/PreSQLEditorService';
import { DATA_FLOW_TYPES } from 'Constants/DataFlowTypes';
import useDisplayName from 'Utils/useDisplayName';

const styles = {
  page: {
    padding: 24
  },
  tableHorizontalOverflow: {
    '& td': {
      minWidth: 100
    }
  },
  modalPreview: {
    '&': {
      width: '1257px',
      maxWidth: 'none'
    }
  },
  modalAudit: {
    '&': {
      width: '1257px',
      maxWidth: 'none'
    }
  }
};
const CHANGE_HISTORY = 'Change History';
const SOURCE_CONFIGURATION_PATH = '/data-standard-library/source-configuration';
const ADD_STUDY_LIBRARY = 'studyLibraryAdd';

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const LIBRARY_TYPE = {
  cdisc: 'CDISC ODM',
  cdr: 'CDR Tabular',
  q2Lab: 'Q2LAB',
  qecg: 'QECG',
  ruleset: 'Ruleset',
  customSQL: 'Custom SQL Library'
};

const ActiveCell = ({ row, column: { accessor } }) => {
  const isactive = row[accessor];
  if (isactive === 'Active') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0 0' }}>
        <span
          style={{
            width: '5px',
            height: '5px',
            borderRadius: '100%',
            background: '#00C221',
            marginRight: '4px'
          }}></span>
        <Typography variant="title1" style={{ fontSize: '14px', margin: '3px 0' }}>
          {isactive}
        </Typography>
      </div>
    );
  } else {
    return <div>{isactive}</div>;
  }
};

const ActionCell = (props) => {
  const { row, studyDataList } = props;
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [downloadModal, setDownloadModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setData] = useState({});
  const [previewModal, setPreviewModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [auditModal, setAuditModal] = useState(false);
  const [value, setValue] = React.useState(false);
  const [auditData, setAuditData] = useState({});
  const [deleteButton, showDeleteButton] = React.useState(false);
  const [reasonForDelete, setReasonFordelete] = React.useState('');
  const [helpText, setHelpText] = React.useState(false);
  const [rowsCount, setRowsCount] = useState(10);
  const [sortedColumn, setSortedColumn] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [paginationFilter, setPaginationFilter] = useState([]);
  const [previewRuleset, setPreviewRuleset] = useState([]);
  const { studyLibraryRowData } = useSelector((state) => state.EditStudyData);
  const [previewPreSQLData, setPreviewPreSQLData] = useState([]);
  const selectedProtocol = useSelector((state) => state.StudyLibraryData.protocol);
  const getDisplayName = useDisplayName();

  const paginationData = { sortOrder, pageNumber, sortedColumn, rowsCount, paginationFilter };
  const paginationFunctions = {
    setRowsCount,
    setSortOrder,
    setSortedColumn,
    setPageNumber,
    setPaginationFilter
  };
  const CHANGE_HISTORY = 'Change History';
  const SOURCE_CONFIGURATION_PATH = '/data-standard-library/source-configuration';

  const onRowEdit = (row) => {
    const modifiedRow = { ...row };
    if (modifiedRow.isCustomSQLLibrary && LIBRARY_TYPE.cdr) {
      modifiedRow.libraryType = LIBRARY_TYPE.customSQL;
    }
    dispatch(setEditStudyLibraryData(modifiedRow));
    navigate('/data-standard-library/edit-study-library', { state: { rulesetData: '' } });
  };

  const getPayload = (row) => {
    const payload = {
      protocolName: row.studyProtocolNumber,
      studyLibraryID: row.libraryID,
      studyLibraryVersion: row.libraryVersion,
      studyLibraryType: row.libraryType,
      isHouseKeepColumns: value,
      userID: row.userId,
      createDate: row.auditDateUnformated,
      trialName: row.libraryType !== LIBRARY_TYPE.cdr ? row.source : null
    };
    return payload;
  };

  const isSuccess = (payload) => {
    if (payload && payload.length) {
      for (let item of payload) {
        if (!item.success) {
          dispatch(showBanner({ variant: 'error', message: payload.message }));
          return false;
        }
        return true;
      }
    } else {
      if (!payload.success || payload.errors) {
        dispatch(showBanner({ variant: 'error', message: payload.message || payload.title }));
        return false;
      }
      return true;
    }
  };

  const removeNullProperties = (obj) => {
    let ignoreColumns = ['userID', 'createDate'];

    Object.keys(obj).forEach((key) => {
      let value = obj[key];

      let hasProperties = value && Object.keys(value).length > 0;

      if (value === null || ignoreColumns.includes(key)) {
        delete obj[key];
      } else if (typeof value !== 'string' && hasProperties) {
        removeNullProperties(value);
      }
    });

    return obj;
  };

  const excelObj = (data, name) => {
    const obj = {};
    obj[name] = data[name];
    obj['errorCode'] = data.errorCode;
    obj['message'] = data.message;
    obj['success'] = data.success;
    return obj;
  };

  const cdrExcel = async (row) => {
    const payload = getPayload(row);
    const Data = await getData(payload);
    const finalData = removeNullProperties(Data);
    const isAllow = isSuccess(finalData);
    isAllow && jsonToExcelStudyLibrary([finalData], 'cdrExcel.xlsx', ['cdr']);
  };

  const q2LabExcel = async (row) => {
    const payload = getPayload(row);
    const Data = await getData(payload);
    const finalData = removeNullProperties(Data);
    const isAllow = isSuccess(finalData);
    isAllow && jsonToExcelStudyLibrary([finalData], 'q2LabExcel.xlsx', ['q2']);
    setLoading(false);
  };

  const cdiscExcel = async (row) => {
    const NewPayload = getPayload(row);
    const Data = await getData(NewPayload);
    const finalData = removeNullProperties(Data);
    const codeList = excelObj(finalData, 'codeLists');
    const edcOdmForms = excelObj(finalData, 'edcOdmForms');
    const edcOdmVisits = excelObj(finalData, 'edcOdmVisits');
    const measurements = excelObj(finalData, 'measurements');
    const newResponse = [edcOdmVisits, edcOdmForms, codeList, measurements];
    const isAllow = isSuccess(newResponse);
    isAllow &&
      jsonToExcelStudyLibrary(newResponse, 'cdiscExcel.xlsx', [
        'Visits',
        'CRFMetadata',
        'CodeList',
        'Measurement'
      ]);
  };

  const getPreSQLData = async (row) => {
    const dataSetRecord = [];
    const { protocolNumber, sponsor } = selectedProtocol;
    const response = await dispatch(
      getPreSQLQueries({
        libraryID: row?.libraryID,
        version: row?.libraryVersion
      })
    ).then(unwrapResult);
    if (response && response.data && response.data.success) {
      const { preSQLQueries } = response.data;
      preSQLQueries?.forEach((data) => {
        const { datasetName, queryColumns, source, studyID, isQueryActive, queryString } = data;
        if (isQueryActive) {
          const columns = queryColumns.split(',').map((item) => item.trim());
          columns.forEach((column) => {
            dataSetRecord.push({
              protocolNumber: protocolNumber.toString(),
              sponsor: sponsor.toString(),
              datasetName: datasetName.toString(),
              studyID: studyID.toString(),
              queryColumns: column.toString(),
              source: getDisplayName(source.toString()),
              queryString: queryString
            });
          });
        }
      });
      setPreviewPreSQLData(dataSetRecord);
      return dataSetRecord;
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
  };

  const getVariableRuleSetsOnEdit = async (studyLibraryRowData) => {
    const variableRuleSetsResp = await dispatch(getVariableRuleSets(studyLibraryRowData)).then(
      unwrapResult
    );
    if (variableRuleSetsResp?.data?.success) {
      let variableRulesets =
        variableRuleSetsResp.data.variableRulesets?.map((row) => ({
          ...row,
          expressions:
            row.expressions?.map((_expression) => ({ id: uuidv4(), expression: _expression })) || []
        })) || [];
      setPreviewRuleset(variableRulesets);
      setLoading(false);
      return variableRulesets;
    } else {
      setPreviewRuleset([]);
      dispatch(showBanner({ variant: 'error', message: variableRuleSetsResp?.data?.message }));
    }
  };

  const downloadRulesets = async (row) => {
    const rulesets = await getVariableRuleSetsOnEdit(row);
    const newFormattedRuleSets = [];
    rulesets.forEach((item) => {
      newFormattedRuleSets.push({
        VariableName: item.variableName,
        Label: item.label,
        Expression: item.expressions.map(item => item.expression).join('\n')
      });
    });
    jsonToExcel(newFormattedRuleSets, 'RuleSet.xlsx', 'Ruleset');
  };

  const downloadPreSqlData = async (row) => {
    const preSQLData = await getPreSQLData(row);
    const { protocolNumber, sponsor } = selectedProtocol;
    const formatedPreSQLData = preSQLData?.map((dataset) => {
      const { datasetName, queryColumns, source, studyID, isQueryActive, queryString } = dataset;
      return {
        'Protocol Number': protocolNumber.toString(),
        Sponsor: sponsor.toString(),
        'Dataset Name': datasetName.toString(),
        'Study ID': studyID.toString(),
        Column: queryColumns.toString(),
        Source: getDisplayName(source.toString()),
        Query: queryString
      };
    });
    jsonToExcel(formatedPreSQLData, 'Custom SQL Library.xlsx', LIBRARY_TYPE.customSQL);
  };

  const qecgExcel = async (row) => {
    const payload = getPayload(row);
    const Data = await getData(payload);
    const finalData = removeNullProperties(Data);
    const isAllow = isSuccess(finalData);
    isAllow && jsonToExcelStudyLibrary([finalData], 'qecgExcel.xlsx', ['qecg']);
  };

  const reasonforDeleteValue = (e) => {
    if (e.target.value !== '') {
      if (e.target.value.length > 400) {
        setReasonFordelete(e.target.value.substring(0, e.target.value.length - 1));
        showDeleteButton(true);
        setHelpText(true);
      } else {
        setReasonFordelete(e.target.value);
        showDeleteButton(true);
        setHelpText(false);
      }
    } else {
      setReasonFordelete('');
      showDeleteButton(false);
      setHelpText(false);
    }
  };

  const getExportData = async (row) => {
    const { libraryType, isCustomSQLLibrary } = row;
    switch (libraryType) {
      case LIBRARY_TYPE.cdisc:
        cdiscExcel(row);
        setDownloadModal(false);
        break;
      case LIBRARY_TYPE.cdr:
        if (!isCustomSQLLibrary) {
          cdrExcel(row);
          setDownloadModal(false);
          break;
        }
      case LIBRARY_TYPE.q2Lab:
        q2LabExcel(row);
        setDownloadModal(false);
        break;
      case LIBRARY_TYPE.qecg:
        qecgExcel(row);
        setDownloadModal(false);
        break;
      case LIBRARY_TYPE.ruleset:
        downloadRulesets(row);
        setDownloadModal(false);
        break;
    }
    if (isCustomSQLLibrary) {
      downloadPreSqlData(row);
      setDownloadModal(false);
    }
  };

  const handleDelete = async (row) => {
    let updatedMetaDataVersions =
      row.metaDataVersion && row.metaDataVersion !== null ? row.metaDataVersion.split(',') : [];
    let updatedStudyVersions =
      row.studyVersion !== '' && row.studyVersion !== null ? row.studyVersion.split(',') : [];
    const payload = {
      studyLibraryData: {
        studyProtocolNumber: row.studyProtocolNumber,
        libraryID: row.libraryID,
        libraryType: row.libraryType,
        libraryName: row.libraryName,
        libraryDescription: row.libraryDescription,
        libraryVersion: row.libraryVersion,
        source: row.source,
        metaDataVersions: updatedMetaDataVersions,
        isactive: row.isactive === 'Active' ? true : false,
        userId: row.userId,
        vendorName: row.vendorName,
        createdDate: row.createdDate,
        auditType: 'DELETE',
        auditDate: new Date().toISOString(),
        studyId: row.studyId ? row.studyId : 0,
        studyVersions: updatedStudyVersions,
        reasonForChange: reasonForDelete ? reasonForDelete : null,
        dataSetTypeID: row.dataSetTypeID,
        isCustomSQLLibrary: row.isCustomSQLLibrary,
        isLibraryDisable: row?.isLibraryDisable,
      },
      studyLibraryEDCStudyID: row.studyId ? row.studyId : 0
    };
    const saveResponse = await dispatch(saveAddStudyLibrary(payload)).then(unwrapResult);
    if (saveResponse && saveResponse.data.success) {
      dispatch(showBanner({ variant: 'success', message: saveResponse.data.message }));
      setDeleteModal(false);
      dispatch(getUpdatedStudyLibraries(true));
      dispatch(clearStudyLibrarySources());
      dispatch(setEditStudyLibraryData({}));
      return;
    } else {
      dispatch(showBanner({ variant: 'error', message: saveResponse.data.message }));
      setDeleteModal(false);
      return;
    }
  };

  const onExport = async (row) => {
    setDownloadModal(true);
  };

  const onDelete = (row) => {
    showDeleteButton(false);
    setDeleteModal(true);
    setData({
      type: 'error',
      message:
        'Please provide a reason for deleting this library. Once you delete, this action cannot be undone.',
      title: 'Delete Study Library',
      label: 'Delete',
      subtitle: 'Optional Subtitle'
    });
  };

  const onPreview = async (row) => {
    row.libraryType === 'Ruleset' && (await getVariableRuleSetsOnEdit(row));
    dispatch(getPreviewData({ ...row }));
    setPreviewModal(true);
    if (row.isCustomSQLLibrary) {
      await getPreSQLData(row);
    }
  };

  const onRowEditRuleSet = async (row) => {
    const rulesetData = await getVariableRuleSetsOnEdit(row);
    dispatch(setEditStudyLibraryData(row));
    navigate('/data-standard-library/edit-study-library', { state: { rulesetData: rulesetData } });
  };

  const onAuditHistory = async (row) => {
    setAuditModal(true);
    const payload = {
      studyLibraryID: row.libraryID
    };
    const resArr = [];
    const auditData = await dispatch(studyAuditHistory(payload)).then(unwrapResult);
    if (auditData && auditData.data && auditData.data.success) {
      if (auditData.data.success) {
        auditData.data.studyAuditHistories.map((el) => {
          resArr.push({
            userId: el.userId,
            createDate: dateFormatByType(el.createDate, 'Table'),
            studylibraryName: el.studylibraryName,
            reasonForChange: el.reasonForChange ? el.reasonForChange : '-',
            auditType: el.auditType,
            studylibraryVersion: el.studylibraryVersion,
            studylibraryId: el.studylibraryId
          });
        });
        setAuditData(resArr);
      }
    } else {
      setAuditModal(false);
      dispatch(showBanner({ variant: 'error', message: auditData.data.message }));
    }
  };

  const handleClose = () => {
    setDeleteModal(false);
    setAuditModal(false);
    setDownloadModal(false);
    setReasonFordelete('');
  };

  const handlePreviewModal = () => {
    setPreviewModal(false);
  };

  const menuItems = useMemo(() => {
    if (
      (protocol.isDataSouceConfigured && !protocol.isActive) ||
      row.auditType === 'DELETE' ||
      row.isactive === 'Inactive'
    )
      return [
        {
          text: 'Preview',
          onClick: () => onPreview(row),
          disabled: hasNoAccess(row.studyProtocolNumber, 'studyLibraryPreview')
        },
        {
          text: 'Export',
          onClick: () => onExport(row),
          disabled: hasNoAccess(row.studyProtocolNumber, 'studyLibraryExport')
        },
        {
          text: CHANGE_HISTORY,
          onClick: () => onAuditHistory(row),
          disabled: hasNoAccess(row.studyProtocolNumber, 'studyLibraryChangeHistory')
        }
      ];
    else
      return [
        {
          text: 'Edit',
          onClick: () => {
            row.libraryType !== 'Ruleset' ? onRowEdit(row) : onRowEditRuleSet(row);
          },
          disabled: hasNoAccess(row.studyProtocolNumber, 'studyLibraryEdit') || row?.isLibraryDisable
        },
        {
          text: 'Delete',
          onClick: () => onDelete(row),
          disabled: hasNoAccess(row.studyProtocolNumber, 'studyLibraryDelete') || row?.isLibraryDisable
        },
        {
          text: 'Preview',
          onClick: () => onPreview(row),
          disabled: hasNoAccess(row.studyProtocolNumber, 'studyLibraryPreview')
        },
        {
          text: 'Export',
          onClick: () => onExport(row),
          disabled: hasNoAccess(row.studyProtocolNumber, 'studyLibraryExport')
        },
        {
          text: CHANGE_HISTORY,
          onClick: () => onAuditHistory(row),
          disabled: hasNoAccess(row.studyProtocolNumber, 'studyLibraryChangeHistory')
        }
      ];
  }, [studyDataList, row, protocol]);

  const handleChange = (e, checked) => {
    setValue(checked);
  };

  const previewModalMessageDisplay = () => {
    if (row.libraryType === 'Ruleset') {
      return (
        <RuleSetEditor
          preview
          previewTitle={row.libraryName}
          uploadLibraryRuleSet={previewRuleset}
        />
      );
    } else if (row.libraryType === 'CDISC ODM') {
      return <PreviewTabs data={row.libraryType} />;
    } else if (row.isCustomSQLLibrary) {
      return <PreSQLPreviewTable previewPreSQLData={previewPreSQLData} />;
    } else {
      return (
        <PreviewTable
          rowDataInfo={row}
          data={row.libraryType}
          paginationData={paginationData}
          paginationFunctions={paginationFunctions}
        />
      );
    }
  };

  return (
    <>
      <Tooltip title="Actions" disableFocusListener>
        <IconMenuButton id="actions" menuItems={menuItems} size="small">
          <EllipsisVerticalIcon />
        </IconMenuButton>
      </Tooltip>

      <CustomModal
        display={auditModal}
        data={row}
        title={`${CHANGE_HISTORY + ' ' + row.libraryName}`}
        subtitle={row.libraryID}
        message={deleteData.message}
        handleClose={handleClose}
        type="History"
        handleSecondaryAction={() => setAuditModal(false)}
        buttonSecondardyLabel={'Close'}
        body={
          loading ? (
            <div style={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}>
              <Loader />
            </div>
          ) : (
            <StudyAuditHistory rows={auditData} />
          )
        }
        width={classes.modalAudit}
      />

      <CustomModal
        display={downloadModal}
        handleClose={handleClose}
        title={'Export Library'}
        message={
          row.libraryType !== 'Ruleset' && (
            <Checkbox label="Include System fields" checked={value} onChange={handleChange} />
          )
        }
        subtitle={'This file will be downloaded as Excel file.'}
        buttonPrimaryLabel={loading ? <Loader /> : 'Export'}
        handlePrimaryAction={() => {
          getExportData(row);
        }}
        buttonSecondardyLabel={'Cancel'}
        handleSecondaryAction={() => setDownloadModal(false)}
      />
      <CustomModal
        type="Delete"
        display={deleteModal}
        show={deleteButton}
        data={row}
        title={
          <>
            Delete Study Library
            <br />
            <span
              style={{
                fontSize: '13px',
                color: 'black',
                width: '100%',
                float: 'left',
                marginTop: '10px'
              }}>
              {' '}
              {`Library Name: ${row.libraryName}`}
            </span>
            <span style={{ fontSize: '13px', color: 'black' }}>
              {' '}
              {`Library ID: ${row.libraryID}`}
            </span>
          </>
        }
        message={
          <>
            <Typography>
              Please provide a reason for deleting this library.
              <br />
              Once you delete, this action cannot be undone.
            </Typography>
            <div style={{ maxWidth: 400 }}>
              <TextField
                id="reasonforDelete"
                label=""
                name="reasonforDelete"
                value={reasonForDelete}
                placeholder="Enter Text"
                onChange={(e) => reasonforDeleteValue(e)}
                fullWidth
                multiline
                helperText={
                  helpText && <span style={{ color: 'red' }}>Maximum character limit reached</span>
                }
                // error={!deleteButton}
              />
            </div>
          </>
        }
        // message={deleteData.message}
        buttonPrimaryLabel={deleteData.label}
        handlePrimaryAction={() => handleDelete(row)}
        buttonSecondardyLabel={'Cancel'}
        handleClose={handleClose}
        variant={'error'}
        width={classes.modalDelete}
      />
      <CustomModal
        display={previewModal}
        handleClose={handlePreviewModal}
        title={row.libraryName}
        subtitle={row.libraryID}
        type="Preview"
        buttonSecondardyLabel={'Close'}
        message={previewModalMessageDisplay()}
        handleSecondaryAction={() => setPreviewModal(false)}
        width={classes.modalPreview}
      />
    </>
  );
};

const StudyLibrary = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [studyLibraries, setStudyLibraries] = useState([]);
  const [emptyTableGrid, setEmptyTableGrid] = useState(false);
  const [dataTable, setDataTable] = useState(false);
  const [configTable, setDataConfigTable] = useState(false);
  const [statusFlag, setStatusFlag] = useState(true);
  const [inactiveMessage, setInactiveMessage] = useState(false);
  const [inactiveDataTable, setInactiveDataTable] = useState(false);
  const [addStudyButton, setAddStudyButton] = useState(false);
  const [studyDataList, setStudyDataList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const { protocol, updatedStudyLibraries } = useSelector((state) => state.StudyLibraryData);
  const { loading } = useSelector((state) => state.LoaderData);
  const { disableComponentsBasedOnRole } = useSelector((state) => state.LoginFormData);
  const getDisplayName = useDisplayName();

  const ExpandCell = ({ row: { id, handleToggleRow, expanded } }) => {
    return (
      <div style={{ width: 12, marginRight: '0.8rem' }}>
        <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
          <IconButton id="expand" size="small" onClick={() => handleToggleRow(id)}>
            {expanded ? <ChevronDown /> : <ChevronRight />}
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const VersionCell = ({ row: { libraryVersion } }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '24px' }}>
        <span>{libraryVersion}</span>
      </div>
    );
  };

  const EllipsisCell = ({ row, column: { accessor } }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    return (
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '10rem' }}>
        <Tooltip title={row[accessor]} placement="bottom" open={open}>
          <Typography
            variant={'body2'}
            noWrap
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}>
            {row[accessor]}
          </Typography>
        </Tooltip>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'expand',
        customCell: ExpandCell
      },
      {
        header: 'Name',
        accessor: 'libraryName',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('libraryName'),
        width: 90,
        fixedWidth: false
      },

      {
        header: 'Status',
        customCell: ActiveCell,
        accessor: 'isactive',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('isactive'),
        fixedWidth: false
      },
      {
        header: 'Type',
        accessor: 'libraryTypeDisplayName',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('libraryTypeDisplayName'),
        fixedWidth: false
      },
      // {
      //   header: 'Trial Name',
      //   accessor: 'source',
      //   sortFunction: compareStrings,
      //   filterComponent: TextFieldFilter,
      //   filterFunction: createStringSearchFilter('source')
      // },
      {
        header: 'Trial Name/Data Flow Type',
        accessor: 'displayTrialName',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('displayTrialName'),
        fixedWidth: false
      },
      {
        header: 'Data Set Type',
        accessor: 'dataSetDisplayName',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('dataSetDisplayName'),
        fixedWidth: false
      },
      {
        header: 'Version',
        accessor: 'libraryVersion',
        sortFunction: compareNumbers,
        filterFunction: numberSearchFilter('libraryVersion'),
        filterComponent: IntegerFilter,
        customCell: VersionCell,
        width: 68,
        fixedWidth: false
      },
      {
        header: 'Metadata Version',
        accessor: 'metaDataVersion',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('metaDataVersion'),
        customCell: EllipsisCell,
        fixedWidth: false
      },
      {
        header: 'Description',
        accessor: 'libraryDescription',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('libraryDescription'),
        customCell: EllipsisCell,
        fixedWidth: false
      },
      {
        header: 'Vendor',
        accessor: 'vendorName',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('vendorName'),
        fixedWidth: false
      },
      {
        header: 'Last Updated User',
        accessor: 'userId',
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('userId'),
        width: 90,
        fixedWidth: false
      },
      {
        header: 'Last Updated Date',
        accessor: 'auditDate',
        sortFunction: compareDates,
        filterFunction: dateFilter('auditDate'),
        filterComponent: DateFilter,
        fixedWidth: false
      },
      {
        header: 'Audit Type',
        accessor: 'auditType',
        sortFunction: compareStrings,
        filterFunction: createStringSearchFilter('auditType'),
        filterComponent: TextFieldFilter,
        fixedWidth: false
      },
      {
        accessor: 'userId',
        customCell: (props) => <ActionCell {...props} studyDataList={studyDataList} />
      }
    ],
    [studyDataList]
  );

  const EmptyTableTypographyStyleTitle1 = {
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '22px',
    color: '#595959',
    height: 'auto'
  };
  const EmptyTableTypographyStyleTitle2 = {
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '20px',
    color: '#999999',
    marginTop: '5px',
    height: 'auto'
  };

  const EmptyTableTypographyStyleTitle3 = {
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '20px',
    color: '#999999',
    marginTop: '20px',
    height: 'auto'
  };

  const EmptyTableTypographyStyleTitle4 = {
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '20px',
    color: '#999999',
    marginTop: '20px',
    height: 'auto'
  };

  const handleStudyGridData = (e) => {
    setStatusFlag(e.target.checked);
  };

  const handleViewClick = () => () => {
    navigate(SOURCE_CONFIGURATION_PATH);
    dispatch(getDisableCheck(true));
  };

  useEffect(() => {
    let getStudyData = [];
    disableComponentsBasedOnRole &&
      disableComponentsBasedOnRole.map((roleObj) => {
        if (roleObj.module === 'StudyLibrary') {
          roleObj.mappings.map((eachID) => {
            if (eachID.isDisabled) {
              getStudyData.push(eachID.feature);
            }
          });
        }
      });

    setStudyDataList(getStudyData);
  }, [disableComponentsBasedOnRole]);

  const handleEditClick = () => () => {
    navigate(SOURCE_CONFIGURATION_PATH);
    dispatch(getDisableCheck(false));
  };

  const menuItems = [
    // {
    //   text: 'Edit Source Configuration',
    //   onClick: handleClick(),
    //   disabled: addStudyButton || inactiveDataTable
    // }
    addStudyButton
      ? {
          text: 'View Source Configuration',
          onClick: handleViewClick()
          // disabled: addStudyButton || inactiveDataTable
        }
      : {
          text: 'Edit Source Configuration',
          onClick: handleEditClick(),
          disabled:
            hasNoAccess(protocol.protocolNumber, 'sourceConfigurationEdit') ||
            addStudyButton ||
            inactiveDataTable
        }
  ];

  useEffect(() => {
    if (updatedStudyLibraries) {
      getStudyLibrary();
      dispatch(getUpdatedStudyLibraries(false));
    }
  }, [updatedStudyLibraries === true]);

  useEffect(() => {
    dispatch(getStudyFormObject(''));
    dispatch(setEditStudyLibraryData(''));
    dispatch(getDisableCheck(false));
    return () => {
      dispatch(openStudyPanel(true));
    };
  }, []);

  const CustomButtonHeader = ({ toggleFilters }) => {
    return (
      <div>
        <Typography
          style={{ fontSize: '13px', margin: '0px 6px 0px 0px' }}
          variant="subtitle"
          gutterBottom>
          {'View Active Libraries'}
        </Typography>
        <Switch checked={statusFlag} size="small" onChange={(e) => handleStudyGridData(e)} />
        <Button
          size="small"
          variant="secondary"
          icon={Filter}
          style={{ marginRight: 15 }}
          onClick={toggleFilters}>
          Filter
        </Button>

        {addStudyButton || inactiveDataTable ? (
          <Tooltip title="The selected study is inactive">
            <span style={{ display: 'inline-block' }}>
              <Button
                variant="primary"
                size="small"
                disabled={
                  hasNoAccess(protocol.protocolNumber, ADD_STUDY_LIBRARY) ||
                  addStudyButton ||
                  inactiveDataTable
                }
                data-testid="add-button">
                Add Study Library
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Link
            to="/data-standard-library/add-study-library"
            style={{
              pointerEvents: hasNoAccess(protocol.protocolNumber, ADD_STUDY_LIBRARY) && 'none'
            }}>
            <Button
              variant="primary"
              size="small"
              disabled={
                hasNoAccess(protocol.protocolNumber, ADD_STUDY_LIBRARY) ||
                addStudyButton ||
                inactiveDataTable
              }
              data-testid="add-study-button">
              Add Study Library
            </Button>
          </Link>
        )}

        <Tooltip title="Actions" disableFocusListener>
          <IconMenuButton
            id="actions-2"
            menuItems={menuItems}
            size="small"
            disabled={inactiveDataTable}>
            <EllipsisVertical />
          </IconMenuButton>
        </Tooltip>
      </div>
    );
  };

  const handleSourceClick = () => {
    navigate(SOURCE_CONFIGURATION_PATH);
  };

  const getStudyPermissions = async (protocol) => {
    const permissionsInfo = await dispatch(getProtocolAuth(protocol)).then(unwrapResult);
    let protocolObj = {};
    if (permissionsInfo.data.success) {
      let permissionJson = permissionsInfo.data.protocolAuth;
      protocolObj[protocol] = permissionJson;
      setProtocolPermissions(protocolObj);
      return true;
    } else {
      return false;
    }
  };

  const getStudyLibrary = async () => {
    if ('isStudyLibraryConfigured' in protocol) {
      return;
    }
    dispatch(showLoader());
    try {
      let studyData;
      let filteredData = [];
      const permissionsFlag =
        Object.keys(protocol).length > 0 && (await getStudyPermissions(protocol.protocolNumber));
      if (protocol && Object.entries(protocol).length > 0 && permissionsFlag) {
        if (protocol.isDataSouceConfigured && protocol.isActive) {
          studyData = await dispatch(getStudyLibraryGrid(protocol.protocolNumber)).then(
            unwrapResult
          );
          if (studyData && studyData.data && studyData.data.success) {
            let response = studyData.data.studyLibraries;
            let studyLibraries = [];
            if (response.length > 0) {
              response.map((el, i) => {
                studyLibraries.push({
                  studyProtocolNumber: el.studyProtocolNumber,
                  libraryType: el.libraryType,
                  libraryName: el.libraryName,
                  libraryTypeDisplayName: el.isCustomSQLLibrary
                    ? el.displayName
                    : getDisplayName(el.libraryType),
                  libraryVersion: el.libraryVersion,
                  source: el.source,
                  trialName:
                    el.libraryType !== LIBRARY_TYPE.cdr
                      ? el.source
                      : el.isCustomSQLLibrary && el.customSQLDataSource === 'CDISC ODM'
                      ? el.edcTrailName
                      : el.isCDRTestFlow
                      ? DATA_FLOW_TYPES.TEST
                      : DATA_FLOW_TYPES.PROD,
                  displayTrialName: [
                    el.libraryType !== LIBRARY_TYPE.cdr
                      ? el.source
                      : el.isCustomSQLLibrary && el.customSQLDataSource === 'CDISC ODM'
                      ? el.edcTrailName
                      : el.isCDRTestFlow
                      ? DATA_FLOW_TYPES.TEST
                      : DATA_FLOW_TYPES.PROD,
                    el.ecrfTrialName
                  ]
                    .filter(Boolean)
                    .join(' - '),
                  dataSetTypeID: el.dataSetTypeID,
                  dataSetDisplayName: el.dataSetDisplayName || 'N/A',
                  createdDate: el.insertedDate,
                  id: i,
                  libraryID: el.libraryID,
                  metaDataVersion:
                    el.libraryType === 'CDISC ODM' ? el.metaDataVersions.join(',') : '',
                  isactive: el.isactive ? 'Active' : 'Inactive',
                  userId: el.userId,
                  vendorName: el.vendorName,
                  auditType: el.auditType,
                  auditDate: dateFormatByType(el.auditDate, 'Table'),
                  auditDateUnformated: el.auditDate,
                  studyId: el.studyId,
                  libraryDescription: el.libraryDescription === '' ? '-' : el.libraryDescription,
                  studyVersion: el.libraryType === 'CDISC ODM' ? el.studyVersions.join(',') : null,
                  isCustomSQLLibrary: el.isCustomSQLLibrary,
                  ecrfTrialName: el.ecrfTrialName,
                  isLibraryDisable: el.isLibraryDisable,
                });
              });
              if (statusFlag) {
                studyLibraries.forEach((el) => {
                  if (el.isactive === 'Active') {
                    filteredData.push(el);
                  }
                });
                setStudyLibraries(filteredData);
                dispatch(getCheckboxFlag(true));
              } else {
                setStudyLibraries(studyLibraries);
              }
              setDataTable(true);
              setEmptyTableGrid(false);
            } else {
              setEmptyTableGrid(true);
              setStudyLibraries([]);
              dispatch(getCheckboxFlag(false));
            }
          } else if (studyData && studyData.data && !studyData.data.success) {
            setEmptyTableGrid(false);
            setDataTable(false);
            setStudyLibraries([]);
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          } else {
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          }
        } else if (!protocol.isDataSouceConfigured && !protocol.isActive) {
          setInactiveMessage(true);
          setInactiveDataTable(false);
        } else if (protocol.isDataSouceConfigured && !protocol.isActive) {
          studyData = await dispatch(getStudyLibraryGrid(protocol.protocolNumber)).then(
            unwrapResult
          );
          if (studyData && studyData.data && studyData.data.success) {
            let response = studyData.data.studyLibraries;
            let studyLibraries = [];
            if (response.length > 0) {
              response.map((el, i) => {
                studyLibraries.push({
                  studyProtocolNumber: el.studyProtocolNumber,
                  libraryType: el.libraryType,
                  libraryName: el.libraryName,
                  libraryDescription: el.libraryDescription === '' ? '-' : el.libraryDescription,
                  libraryVersion: el.libraryVersion,
                  // source: el.source,
                  source: el.libraryType !== LIBRARY_TYPE.cdr ? el.source : '-',
                  createdDate: el.insertedDate,
                  id: i,
                  libraryID: el.libraryID,
                  metaDataVersion: el.metaDataVersions.join(','),
                  isactive: el.isactive ? 'Active' : 'Inactive',
                  userId: el.userId,
                  vendorName: el.vendorName,
                  auditType: el.auditType,
                  auditDate: dateFormatByType(el.auditDate, 'Table'),
                  auditDateUnformated: el.auditDate,
                  studyId: el.studyId,
                  studyVersion: el.studyVersions.join(','),
                  dataSetTypeID: el.dataSetTypeID,
                  dataSetDisplayName: el.dataSetDisplayName || 'N/A'
                });
              });
              if (statusFlag) {
                studyLibraries.forEach((element) => {
                  if (element.isactive === 'Active') {
                    filteredData.push(element);
                  }
                });
                setDataTable(true);
                setEmptyTableGrid(false);
                setStudyLibraries(filteredData);
                setAddStudyButton(true);
              } else {
                setAddStudyButton(true);
                setEmptyTableGrid(true);
                setStudyLibraries(studyLibraries);
              }
              setDataTable(true);
              setEmptyTableGrid(false);
            } else {
              setStudyLibraries([]);
              setInactiveDataTable(true);
              setInactiveMessage(false);
            }
          } else if (studyData && studyData.data && !studyData.data.success) {
            setEmptyTableGrid(false);
            setDataTable(false);
            setStudyLibraries([]);
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          } else {
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          }
        } else {
          setDataTable(false);
          setEmptyTableGrid(false);
          setDataConfigTable(true);
        }
      } else {
        setDataTable(false);
        setEmptyTableGrid(false);
        setDataConfigTable(false);
      }
      dispatch(closeLoader());
    } catch (err) {
      dispatch(closeLoader());
      dispatch(showBanner({ variant: 'error', message: err.message }));
    }
  };

  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Standard Libraries',
          path: '/data-standard-library/study-library'
        }
      ])
    );

    dispatch(showLoader());
    setDataTable(false);
    setEmptyTableGrid(false);
    setDataConfigTable(false);
    setStudyLibraries([]);
    setInactiveMessage(false);
    setInactiveDataTable(false);
    setAddStudyButton(false);
    getStudyLibrary();
  }, [statusFlag, protocol]);

  const navigateToSourceConfigPage = () => {
    navigate(SOURCE_CONFIGURATION_PATH);
  };

  const DetailRow = ({ row }) => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        style={{ backgroundColor: '#f8f9fb', width: '100%', padding: '0.5rem 2rem' }}>
        <Box
          style={{
            minWidth: '300px',
            marginRight: '2rem'
          }}>
          <Typography style={{ fontWeight: 500, color: neutral8 }}>{'Library ID'}</Typography>
          <Typography style={{ color: neutral7 }} variant="body2">
            {row.libraryID}
          </Typography>
        </Box>
        {row.libraryType === LIBRARY_TYPE.cdr && (
          <Box
            style={{
              minWidth: '300px'
            }}>
            <Typography style={{ fontWeight: 500, color: neutral8 }}>{'Data Sets'}</Typography>
            <Typography style={{ color: neutral7 }} variant="body2">
              {row.source}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
  const studyLibraryResultShow = () => {
    if (configTable) {
      return (
        <DataVizCard title="Study Libraries" subtitle="0 items">
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            <Cog />
          </Typography>
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            No data sources configured
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
            Add a source configuration to get started
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle3}>
            <Button
              variant="primary"
              size="small"
              onClick={navigateToSourceConfigPage}
              disabled={hasNoAccess(protocol.protocolNumber, 'sourceConfigurationAdd')}>
              Add Source Configuration
            </Button>
          </Typography>
        </DataVizCard>
      );
    } else if (inactiveMessage) {
      return (
        <DataVizCard title="Study Libraries" subtitle="0 items">
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            <Cog />
          </Typography>
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            Study Inactive
          </Typography>
        </DataVizCard>
      );
    } else if (inactiveDataTable) {
      return (
        <DataVizCard title="Study Libraries" subtitle="0 items">
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            No study libraries found
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
            Add study library to get started.
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle3}>
            <Button
              variant="primary"
              size="small"
              disabled={
                hasNoAccess(protocol.protocolNumber, ADD_STUDY_LIBRARY) || inactiveDataTable
              }>
              Add Study Library
            </Button>
          </Typography>
        </DataVizCard>
      );
    } else if (dataTable) {
      return (
        <TableWrapper data-testid="table-study">
          <Table
            title={
              <div style={{ display: 'block' }}>
                <div>
                  Study Libraries
                  <Button size="small" onClick={getStudyLibrary}>
                    <Refresh />
                  </Button>
                </div>
                <div>
                  <Typography variant="body2" gutterBottom>
                    {`${studyLibraries.length} ${studyLibraries.length !== 1 ? 'items' : 'item'}  `}
                  </Typography>
                </div>
              </div>
            }
            rows={studyLibraries.map((row) => {
              return {
                ...row,
                key: row.id,
                expanded: expandedRows.includes(row.id) ? true : false,
                handleToggleRow
              };
            })}
            ExpandableComponent={DetailRow}
            columns={columns}
            initialSortedColumn="auditDate"
            initialSortOrder="desc"
            CustomHeader={(props) => <CustomButtonHeader {...props} />}
            rowsPerPageOptions={[10, 20, 50, 100, 'All']}
            tablePaginationProps={{
              labelDisplayedRows: DisplayedRowsLabel,
              truncate: true
            }}
            hasScroll
            maxHeight={650}
          />
        </TableWrapper>
      );
    } else if (emptyTableGrid) {
      return (
        <DataVizCard title="Study Libraries" subtitle="0 items">
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            No study libraries found
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
            Add study library to get started.
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle3}>
            <Link
              to="/data-standard-library/add-study-library"
              style={{
                pointerEvents: hasNoAccess(protocol.protocolNumber, ADD_STUDY_LIBRARY) && 'none'
              }}>
              <Button
                variant="primary"
                size="small"
                disabled={
                  hasNoAccess(protocol.protocolNumber, ADD_STUDY_LIBRARY) || inactiveDataTable
                }>
                Add Study Library
              </Button>
            </Link>
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle4}>
            <Button
              variant="primary"
              size="small"
              onClick={handleSourceClick}
              disabled={hasNoAccess(protocol.protocolNumber, 'sourceConfigurationEdit')}>
              Edit Source Configuration
            </Button>
          </Typography>
        </DataVizCard>
      );
    } else {
      return (
        <Grid
          container
          style={{
            display: 'grid',
            placeItems: 'center',
            position: 'relative',
            padding: '13rem'
          }}>
          <img height="23" src={BookLibrary} style={{ marginBottom: '1rem' }} />
          <Grid xs={6}>
            <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
              Nothing to See Here
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
              Select a study to begin.
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <div data-testid="red" className={classes.page}>
      <Typography variant="body1" gutterBottom>
        <Grid container spacing={3}>
          <Grid item xs={12} data-testid="study-lib">
            {loading ? (
              <DataVizCard data-testid="loader">
                <Loader />
              </DataVizCard>
            ) : (
              studyLibraryResultShow()
            )}
          </Grid>
        </Grid>
      </Typography>
    </div>
  );
};

export default StudyLibrary;
