import React, { forwardRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Typography from 'apollo-react/components/Typography';
import { GetStudyLibrarySourceByDataSetType } from 'Redux/Service/AddStudyLibraryService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  GetNonECRFDatasets,
  GetReservedLibraryInfo,
  RunNonEcrfDatasets
} from 'Redux/Service/DataProductsService';
import {
  setIsRunAllExecuted,
  setLibraryName,
  setNextEnabled,
  setNonEcrfDatasets,
  setProductMnemonic,
  setTargetLibrary
} from 'Redux/Slice/SDHDataProductSlice';
import { GetViewGlobalLibraries } from 'Redux/Service/GlobalLibraryService';
import { NON_ECRF_LIBRARY_NAME, NON_ECRF_PRODUCT_MNEMONIC } from 'Utils/Constants';
import AddNonECRFForm from './components/AddNonECRFForm';
import NonECRFDataListTable from './components/NonECRFDataListTable';
import NonECRFTargetDataTable from './components/NonECRFTargetDataTable';

export const nonECRFSchema = yup.object({
  trialName: yup.string().required('Trial Name/Environment Name is required'),
  description: yup.string().max(400, 'Maximum ${max} characters are allowed').optional(),
  targetRowSelected: yup.boolean().required()
});

const uniqueByEdcTrialName = (array) => {
  const seen = new Set();
  return array.filter((item) => {
    const duplicate = seen.has(item.edcTrialName);
    seen.add(item.edcTrialName);
    return !duplicate;
  });
};

const NonECRFData = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [edcTrialOptions, setEdcTrialOptions] = useState([]);

  const [nonEcrfTargetLibrary, setNonEcrfTargetLibrary] = useState([]);

  const studyLibraryData = useSelector((state) => state.StudyLibraryData);
  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);

  const {
    selectedTrialName,
    description,
    nonEcrfDatasets = [],
    isRunAllExecuted = false,
    workFlowModel
  } = sdhDataProduct;

  const { protocol } = studyLibraryData;

  const formik = useFormik({
    initialValues: {
      trialName: selectedTrialName ? selectedTrialName?.edcTrialName : '',
      description: description ?? '',
      targetRowSelected: true,
    },
    validationSchema: nonECRFSchema,
    onSubmit: async () => {}
  });

  useEffect(() => {
    dispatch(setNextEnabled(false));
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title: 'SDH Data Product',
          path: `/product-designer/${workFlowModel[0]?.moduleName}`
        }
      ])
    );
  }, []);

  const loadTrialOptions = async () => {
    try {
      const libraryType = 'CDISC ODM';
      const studyLibrarySources = await dispatch(
        GetStudyLibrarySourceByDataSetType({
          protocolNumber: protocol?.protocolNumber,
          type: libraryType,
          datasetTypeId: 0
        })
      ).then(unwrapResult);

      const { data } = studyLibrarySources;
      if (data?.success) {
        const edcTrialNames =
          data?.odmMetedataSources?.length > 0 ? uniqueByEdcTrialName(data.odmMetedataSources) : [];
        setEdcTrialOptions(edcTrialNames);
      } else {
        dispatch(showBanner({ variant: 'error', message: studyLibrarySources?.data?.message }));
      }
      setLoading(false);
    } catch (error) {
      dispatch(
        showBanner({
          variant: 'error',
          message: 'Something went wrong in GetStudyLibrarySources Method'
        })
      );
    }
  };

  const loadTargetDataSet = async () => {
    try {
      const targetSelectData = await dispatch(
        GetViewGlobalLibraries({ IsActive: true, exceptRuleset: false, isNonEcrfLib: true })
      ).then(unwrapResult);
      
      const { data } = targetSelectData;
      if (data.success) {
        const { libraries } = data;
        setNonEcrfTargetLibrary(libraries);
        
        dispatch(setTargetLibrary(libraries?.length > 0 ? libraries[0] : {}));
      } else {
        dispatch(showBanner({ variant: 'error', message: data.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({ variant: 'error', message: 'Something went wrong in GetLibraries Method' })
      );
    }
  };

  const loadReservedLibraryInfo = async () => {
    try {
      const getReservedLibraryData = await dispatch(GetReservedLibraryInfo()).then(unwrapResult);
      const { data } = getReservedLibraryData;
      if (data?.success) {
        const { productAndLibrary } = data;
        const libraryName = productAndLibrary?.find(
          (item) => item.configName === NON_ECRF_LIBRARY_NAME
        )?.configValue;
        const productMnemonic = productAndLibrary?.find(
          (item) => item.configName === NON_ECRF_PRODUCT_MNEMONIC
        )?.configValue;

        dispatch(setLibraryName(libraryName));
        dispatch(setProductMnemonic(productMnemonic));
      } else {
        dispatch(showBanner({ variant: 'error', message: data?.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({
          variant: 'error',
          message: 'Something went wrong in GetReservedLibraryInfo Method'
        })
      );
    }
  };

  useEffect(async () => {
    loadTrialOptions();
    loadTargetDataSet();
    loadReservedLibraryInfo();
  }, []);

  const { protocolNumber } = protocol;
  const { trialName } = formik.values;
  useEffect(async () => {
    if (trialName) {
      setLoading(true);
      const { data } = await dispatch(
        GetNonECRFDatasets({
          protocolNumber: protocolNumber,
          trialName: trialName
        })
      ).then(unwrapResult);

      if (data.success) {
        const { nonEcrfDatasets: nonEcrfDatasetsApi } = data;
        if (!isRunAllExecuted) {
          dispatch(setNonEcrfDatasets(nonEcrfDatasetsApi));
        }
      }
    }
    setLoading(false);
  }, [trialName]);

  useEffect(() => {
    const allQueriesSuccessful =nonEcrfDatasets?.length > 0 && nonEcrfDatasets.every((dataset) => dataset?.isQuerySuccess);
    const isNextEnabled = nonEcrfTargetLibrary?.length > 0 && allQueriesSuccessful;

    if (isNextEnabled) {
      dispatch(setNextEnabled(isNextEnabled));
      dispatch(setIsRunAllExecuted(isNextEnabled));
    }
  }, [nonEcrfTargetLibrary]);

  const runAllHandler = async () => {
    if (nonEcrfDatasets?.length === 0) {
      return;
    }

    if (isRunAllExecuted) {
      return;
    }

    setLoading(true);
    dispatch(setNextEnabled(false));

    const nonEcrfDatasetsPayload = nonEcrfDatasets.map((item) => ({
      datasetname: item.datasetName,
      description: item.description,
      queries: item.sqlQuery,
      trialName: trialName
    }));
    try {
      const { data } = await dispatch(
        RunNonEcrfDatasets({
          runNonEcrfDatasets: nonEcrfDatasetsPayload,
          protocolNumber: protocolNumber
        })
      ).then(unwrapResult);
      if (data.success) {
        const { nonecrfDatasets: nonecrfDatasetsApi } = data;

        const nonEcrfDatasetsTransoformed = nonEcrfDatasets.map((item) => {
          const additionalItem = nonecrfDatasetsApi.find(
            (add) => add.datasetName === item.datasetName
          );
          return additionalItem ? { ...item, ...additionalItem } : item;
        });

        const allQueriesSuccessful = nonEcrfDatasetsTransoformed.every(
          (dataset) => dataset.isQuerySuccess
        ) && nonEcrfTargetLibrary.length > 0;

        dispatch(setIsRunAllExecuted(allQueriesSuccessful));
        dispatch(setNonEcrfDatasets(nonEcrfDatasetsTransoformed));
        dispatch(setNextEnabled(allQueriesSuccessful));

        setLoading(false);
      } else {
        setLoading(false);
        dispatch(setNextEnabled(false));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Box px={3}>
        <Typography variant="h3">Add Non-eCRF Data Product</Typography>
        <AddNonECRFForm formik={formik} edcTrialOptions={edcTrialOptions} />

        <NonECRFDataListTable
          nonEcrfDatasets={nonEcrfDatasets}
          runAllHandler={runAllHandler}
          loading={loading}
        />

        <NonECRFTargetDataTable nonEcrfTargetLibrary={nonEcrfTargetLibrary} formik={formik} />
      </Box>
    </>
  );
};

const NonECRFDataProducts = forwardRef(NonECRFData);
export default NonECRFDataProducts;
