/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { neutral1 } from 'apollo-react/colors';
import Modal from 'apollo-react/components/Modal';
import Typography from 'apollo-react/components/Typography';
import Button from 'apollo-react/components/Button';
import RuleSetEditor from './RuleSetEditor';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Loader from 'apollo-react/components/Loader';

import { Notification } from 'Components/Common/Notification';
import { dateFormatByType, uuidv4 } from 'Utils';
import { GetAllRuleSets } from 'Redux/Service/RulesetsService';
import { getVariableRuleSets } from 'Redux/Service/AddGlobalLibraryService';
import Table from 'apollo-react/components/Table';
import { GlobalColumns, StudyColumns } from './columns.data';
import ButtonGroup from 'apollo-react/components/ButtonGroup';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import NoRulesetsMessage from './NoRulesetsMessage';

const BORDER_STYLE = '1px solid #4442';
const useStyles = makeStyles({
  modalContainer: {
    fontFamily: 'Proxima Nova',
    height: '90vh',
    overflow: 'hidden',
    maxWidth: '90vw',
    width: '80vw',
    '&>.MuiDialogContent-root': {
      paddingBottom: '0px',
      marginBottom: '1rem',
      borderBottom: BORDER_STYLE
    }
  },
  container: {
    marginBottom: '1rem'
  },
  rowsContainer: {
    boxShadow: '0px 0px 3px #0002'
  },
  header: {
    padding: '1rem',
    backgroundColor: 'white',
    borderRadius: '4px 4px 0 0'
  },
  headerTitle: {
    fontWeight: '600'
  },
  headerSubTitle: {
    fontSize: '14px',
    color: '#444'
  },
  columnsHeader: {
    '&>div': {
      padding: '1rem',
      color: '#595959',
      fontSize: '14px',
      backgroundColor: neutral1
    }
  },
  row: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderBottom: '1px solid #F6F7FB',
    fontSize: '14px',
    color: '#595959',
    cursor: 'pointer !important',
    '&:hover': {
      backgroundColor: '#ECF3FF'
    }
  },
  selectedRow: {
    backgroundColor: '#ECF3FF !important'
  },
  column: {
    padding: '1rem',
    height: '48px'
  },
  stickyHeader: {
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    border: BORDER_STYLE
    // borderBottom: 'none'
  },
  root: {
    '& > div > table': {
      borderTop: 'none',
      border: BORDER_STYLE
    }
  }
});

/**
 * RulesetSelector - Displays a Modal for user to preview & select Global/Study Rulesets
 * @prop {boolean} open - Flag to control the model display
 * @prop {functin} setOpen - Function to set the modal display or hide
 * @prop {functin} onApplyRuleset - Callback function to get the selected Ruleset
 * @prop {boolean} studyOnly - Flag to display only study rulesets
 * @prop {string} protocolNumber - protocol number incase only needed to fetch single protocol rulesets
 * @prop {string} modalTitle - Text to display as Modal Title
 * @prop {string} modalSubtitle - Text to display as Modal Sub Title
 * @prop {string} primaryButtonText - Text to display as Primary Button Text
 * @prop {boolean} returnLibrary - Flag to decide whether to return library or ruleset variables on select
 */
const RulesetSelector = (props) => {
  const {
    open,
    setOpen,
    onApplyRuleset,
    studyOnly,
    protocolNumber,
    modalTitle,
    modalSubtitle,
    primaryButtonText,
    returnLibrary
  } = props;
  const classes = useStyles();
  const [rulesetScope, setRulesetScope] = useState(studyOnly ? 'study' : 'global');
  const [globalRulesets, setGlobalRulesets] = useState([]);
  const [studyRulesets, setStudyRulesets] = useState([]);
  const [selectedLibrary, setSelectedLibrary] = useState({});
  const [rulsetVariableData, setRulsetVariableData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [previewRuleset, setPreviewRuleset] = useState(false);
  const dispatch = useDispatch();
  const returnLibraryValue = returnLibrary ? 'apply' : 'import';

  const fetchMasteredDataSets = async () => {
    setLoading(true);
    let rulesetData;
    try {
      rulesetData = await dispatch(
        GetAllRuleSets({ protocol: protocolNumber, ExcludeGlobal: studyOnly })
      ).then(unwrapResult);
      if (rulesetData && rulesetData.data && rulesetData.data.success) {
        let _globalRulesets = [];
        let _studyRulesets = [];

        rulesetData.data?.rulesets?.forEach((ruleset) => {
          const key = uuidv4();
          (ruleset.category === 'Study' ? _studyRulesets : _globalRulesets).push({
            libraryName: ruleset.libraryName,
            version: ruleset.libraryVersion,
            userid: ruleset.user,
            category: ruleset.category,
            protocolName: ruleset.protocolName,
            libraryID: ruleset.libraryID,
            lastUpdated: dateFormatByType(ruleset.lastUpdated, 'Table'),
            key,
            rowId: key
          });
        });

        setStudyRulesets(_studyRulesets);
        setGlobalRulesets(_globalRulesets);
      } else {
        dispatch(showBanner({ variant: 'error', message: rulesetData.data.message }));
      }
      setLoading(false);
    } catch (err) {
      dispatch(showBanner({ variant: 'error', message: err.message }));
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMasteredDataSets();
  }, []);

  const handlePreviewRuleset = async () => {
    setLoading(true);
    const response = await dispatch(
      getVariableRuleSets({
        libraryID: selectedLibrary.libraryID,
        version: selectedLibrary.version
      })
    ).then(unwrapResult);
    if (response?.data?.success) {
      let variableRulesets =
        response.data.variableRulesets?.map((row) => ({
          ...row,
          expressions:
            row.expressions?.map((_expression) => ({
              id: uuidv4(),
              expression: _expression
            })) || []
        })) || [];
      setRulsetVariableData(variableRulesets?.filter((vairable) => vairable.status));
      setPreviewRuleset(!previewRuleset);
    }
    setLoading(false);
  };

  const buttonProps = previewRuleset
    ? [
        { label: 'Cancel', onClick: () => setOpen(false) },
        {
          label: primaryButtonText || 'Apply Ruleset',
          onClick: () => onApplyRuleset(returnLibrary ? selectedLibrary : rulsetVariableData)
        }
      ]
    : [
        { label: 'Cancel', onClick: () => setOpen(false) },
        {
          label: 'Preview Ruleset',
          onClick: handlePreviewRuleset,
          disabled: !selectedLibrary.libraryID
        }
      ];

  const handleRowSelected = (row) => {
    setSelectedLibrary(row);
  };

  const handleScopeChange = (scope) => {
    setSelectedLibrary({});
    setRulesetScope(scope);
  };

  const buttonGroupProps = [
    {
      label: 'Global Rulesets',
      onClick: () => handleScopeChange('global'),
      variant: rulesetScope === 'global' ? 'primary' : 'secondary',
      style: {
        borderRadius: 0,
        borderBottomLeftRadius: '4px',
        borderTopLeftRadius: '4px',
        width: '10rem'
      }
    },
    {
      label: 'Study Rulesets',
      onClick: () => handleScopeChange('study'),
      variant: rulesetScope === 'study' ? 'primary' : 'secondary',
      style: {
        borderRadius: 0,
        margin: 0,
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        width: '10rem'
      }
    }
  ];

  const rows = useMemo(() => {
    return (rulesetScope === 'global' ? globalRulesets : studyRulesets).map((ruleset) => ({
      ...ruleset,
      className:
        ruleset.rowId === selectedLibrary.rowId
          ? `${classes.row} ${classes.selectedRow}`
          : classes.row
    }));
  }, [selectedLibrary, globalRulesets, studyRulesets, rulesetScope]);

  const [columns, title, subtitle] = useMemo(() => {
    if (rulesetScope === 'global')
      return [GlobalColumns, 'Global Rulesets', `${globalRulesets.length} Global Rulesets`];
    else if (rulesetScope === 'study')
      return [StudyColumns, 'Study Rulesets', `${studyRulesets.length} Study Rulesets`];
  }, [rulesetScope, globalRulesets, studyRulesets]);

  return (
    <Modal
      title={previewRuleset ? 'Preview Ruleset' : modalTitle}
      subtitle={
        previewRuleset ? (
          <>
            Ruleset:&nbsp;
            <span style={{ fontWeight: '600' }}>{selectedLibrary.libraryName}</span>
          </>
        ) : (
          modalSubtitle
        )
      }
      open={open}
      onClose={() => setOpen(false)}
      buttonProps={buttonProps}
      className={classes.modalContainer}
      disableBackdropClick>
      {isLoading ? <Loader isInner></Loader> : null}
      {previewRuleset ? (
        <>
          <Box
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              width: '100%',
              zIndex: 1,
              paddingBottom: '1rem',
              boxShadow: '0px -3px 3px 3px #fff'
            }}>
            <Button
              onClick={() => setPreviewRuleset(!previewRuleset)}
              size={'small'}
              icon={<ChevronLeft />}>
              {'Back to search'}
            </Button>
          </Box>
          <RuleSetEditor
            preview
            uploadLibraryRuleSet={rulsetVariableData}
            previewTitle={selectedLibrary.libraryName}
            stickyTop={'48px'}
            showOnlyActive
          />
        </>
      ) : (
        <>
          {!studyOnly && (
            <Box className={classes.container}>
              <Box display={'flex'} justifyContent={'center'} p={3}>
                <ButtonGroup buttonProps={buttonGroupProps} />
              </Box>
              {!returnLibrary && (
                <Notification
                  message={
                    'A copy of the selected ruleset will be created, any changes at the study level will have no effect on the selected ruleset.'
                  }
                  style={{ margin: '0 0 1.5rem 0' }}
                />
              )}
              <Typography>{`Select a ${rulesetScope} ruleset to ${returnLibraryValue}:`}</Typography>
            </Box>
          )}
          <Table
            classes={{ stickyHeader: classes.stickyHeader, root: classes.root }}
            sx={{
              border: BORDER_STYLE,
              borderRadius: '4px',
              boxShadow: '0px 0px 2px #0002'
            }}
            title={title}
            subtitle={subtitle}
            columns={columns}
            initialSortedColumn="lastUpdated"
            initialSortOrder="desc"
            rows={rows}
            rowId="name"
            rowsPerPageOptions={[50, 100, 200, 'All']}
            tablePaginationProps={{
              labelDisplayedRows: DisplayedRowsLabel,
              truncate: true
            }}
            isSticky
            rowProps={{
              onClick: handleRowSelected
            }}
            emptyProps={{
              content: studyOnly && <NoRulesetsMessage />
            }}
          />
        </>
      )}
    </Modal>
  );
};

export default RulesetSelector;
