import validate from 'Pages/Validator/validate';

let uniqueObj = {};

let mandatoryError = "This Field is Mandatory";
let specialCharMessageError = 'Special characters are not allowed';


const mergeObject = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key === 'expressions') {
        targetObj.expressions = targetObj.expressions || {};
        Object.keys(sourceObj.expressions).forEach((uuid) => {
          if (!targetObj.expressions[uuid]) {
            targetObj.expressions[uuid] = sourceObj.expressions[uuid];
          }
        });
      } else if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

const mergeCordinates = (targetObj, sourceObj) => {
  if (sourceObj && Object.keys(sourceObj).length > 0) {
    Object.keys(sourceObj).forEach((key) => {
      if (key && targetObj[key]) {
        targetObj[key] = { ...targetObj[key], ...sourceObj[key] };
      } else {
        targetObj[key] = sourceObj[key];
      }
    });
  }
};

const checkVariableName = (record, error) => {
  let obj = {variableName: { unique: 'Variable Name Should be unique' }};
  const { variableName } = record;
    let value = `${variableName?.toString().toLowerCase()}`;
    if (Object.keys(uniqueObj).length > 0 && uniqueObj[value]) {

    if(variableName !== ''){
      error[uniqueObj[value]] = {...error[uniqueObj[value]], ...obj} //this is for showing error to the previous one we encountered same with
      return obj;
    }

    return {};
    } else {
      uniqueObj[value] = record.variableRuleSetID;
      return {};
    }
};

const checkExpression = (record, codeListDataMapping, serviceResult, formsObj) => {
  let expressionRes = {};
  let errorCordinatesObj = {};

  record?.expressions?.forEach((item) => {
    let valResult = validate(
      item.expression,
      '',
      codeListDataMapping,
      formsObj,
      serviceResult,
      2
    );

    if (!valResult.isValid) {
      expressionRes.expressions = expressionRes.expressions || {};
      expressionRes.expressions = {
        ...expressionRes.expressions,
        [item.id]: {
          message: valResult.message,
          isValid: valResult.isValid
        }
      };

      errorCordinatesObj.cordinates = {
        ...(errorCordinatesObj.cordinates || {}),
        [item.id]: { ...(valResult.cordinates || {}) }
      };
    }
  });
  return { expressionRes, errorCordinatesObj };
};

const checkMandatory = (record) => {
  let { variableName, expressions, label } = record;
  let obj = {};
  if (!variableName) {
    obj.variableName = { mandatory: mandatoryError };
  }
  expressions?.forEach((item) => {
    if (!item.expression) {
      obj.expressions = obj.expressions || {};
      obj.expressions = { ...obj.expressions, [item.id]: { mandatory: mandatoryError } };
    }
  });
  if (!label) {
    obj.label = { mandatory: mandatoryError };
  }
  return obj;
};

const isSpecialCharacterPresent = (record) => {
  let { variableName } = record;
  let obj = {};
  const regex = /[~`' +=;/!@#$%^&*(),-.?":{}|<>[\]\\]/;
  if (regex.test(variableName)) {
    obj.variableName = { mandatory: specialCharMessageError };
  }
  return obj;
};

export const ruleSetValidator = (records,codeListDataMapping, formsObj, serviceResult) => {
    let errorColumnCount = 0;
    let errorRowsId = [];
    let error = {};
    let errorCordinates = {};
    let specialCharExist = {};
    records.forEach((record) => {
      let obj = {};
      let cordinates ={};

      const mandatory = checkMandatory(record);
      const checkVariable = checkVariableName(record, error);
      const {expressionRes, errorCordinatesObj} = checkExpression(record,codeListDataMapping,serviceResult, formsObj);
      specialCharExist = isSpecialCharacterPresent(record);

      mergeObject(obj, specialCharExist)
      mergeObject(obj, mandatory)
      mergeObject(obj, checkVariable);
      mergeObject(obj, expressionRes);
      mergeCordinates(cordinates, errorCordinatesObj);

      if (obj && Object.keys(obj).length > 0) {
        errorRowsId.push(record.variableRuleSetID);
        errorColumnCount = errorColumnCount + Object.keys(obj).length;
      }

      error[record.variableRuleSetID] = {...error[record.variableRuleSetID], ...obj};
      errorCordinates[record.variableRuleSetID] = cordinates;
    });
    uniqueObj = {};

    const isColumnValid = (rulesetId, columnName) => {
      let columnError = '';
      let row = error[rulesetId] ? error[rulesetId] : {};
      if (row && Object.keys(row).length > 0) {
        columnError = columnName && row[columnName] ? row[columnName] : '';
      }
      return columnError;
    };

    const isExpressionValid = (uuid) =>{
      let cordinates = {};
      let row = errorCordinates[uuid] ? errorCordinates[uuid] : {};
      if (row && Object.keys(row).length > 0) {
        cordinates = row['cordinates'] ? row['cordinates'] : '';
      }
      return cordinates;
    }

    const getColumnErrorCount = () => {
      return errorColumnCount;
    };
    const isSpecialCharPresent = () => {
      return specialCharExist;
    };

    return {
      isColumnValid: isColumnValid,
      getErrorCount: getColumnErrorCount,
      isExpressionValid:isExpressionValid,
      isSpecialCharPresent: isSpecialCharPresent
    };
  };

export default { ruleSetValidator };
