import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import React from 'react';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalMessage from 'Utils/useGlobalMessage';

function SQLTemplateDropdown({
  classes,
  isQueryNameDuplicate,
  isQueryNameLengthExceed,
  qcodeDatasetName,
  sqlQueryTemplateChangeHandler,
  showSqlQueryOption,
}) {
  const { getMessage } = useGlobalMessage();

  return (
    <Select
      label={getMessage(MessageConstants.SQL_TEMPLATE)}
      className={classes.fieldElement}
      size={'small'}
      sx={{
        marginTop: '0.5rem',
        marginBotton: (isQueryNameDuplicate || isQueryNameLengthExceed) && '18px'
      }}
      value={qcodeDatasetName}
      onChange={(e) => {
        sqlQueryTemplateChangeHandler(e);
      }}
      canDeselect={false}  
      >
      {showSqlQueryOption?.length > 0 &&
        showSqlQueryOption.map((qCodeSql) => {
          return (
            <MenuItem value={qCodeSql} key={qCodeSql}>
              {getMessage(MessageConstants[qCodeSql])}
            </MenuItem>
          );
        })}
    </Select>
  );
}

export default SQLTemplateDropdown;
