/*eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import QueryOutput from './QueryOutput';
import Grid from 'apollo-react/components/Grid';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import { uuidv4 } from 'Utils';
import Button from 'apollo-react/components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from 'apollo-react/components/Typography';
import PreSQLAddNewDataset from './PreSQLNewDataset/PreSQLAddNewDataset';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getStudyFormObject } from 'Redux/Slice/AddStudyLibrarySlice';
import Card from 'apollo-react/components/Card';
import Box from 'apollo-react/components/Box';
import { unwrapResult } from '@reduxjs/toolkit';
import { RunPreSQLQueries } from 'Redux/Service/PreSQLEditorService';
import { validatePreSQLQuery } from './PreSQLNewDataset/preSQLValidator';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { DATAFLOWTYPE_PAYLOAD_MAPPING } from 'Constants/DataFlowTypes';

const generateRunPreSQLPayload = (formik, protocol, silentProperties, dataSourceTrailValues) => {
  const dataset = {
    datasetName: formik.values.datasetName,
    dataSource: dataSourceTrailValues.selectedDataSource,
    protocolNumber: protocol.protocolNumber,
    queryString: formik.values.sqlQuery,
    dataBaseName: silentProperties.dataBaseName,
    isAzureEnv: silentProperties.isAzureEnv,
    trialName:
      DATAFLOWTYPE_PAYLOAD_MAPPING[dataSourceTrailValues.selectedTrialName.trialName] ||
      dataSourceTrailValues.selectedTrialName.trialName,
    datasetDescription: formik.values.datasetDescription,
    studyID:
      dataSourceTrailValues?.selectedDataSource === 'CDISC ODM'
        ? dataSourceTrailValues.selectedTrialName.studyID
        : protocol.protocolNumber
  };
  return dataset;
};

const generateDatasetToSave = (formik, protocol, silentProperties, dataSourceTrailValues) => {
  const {
    datasetName,
    dataSource,
    queryString,
    trialName,
    studyID,
    datasetDescription,
    dataBaseName,
    isAzureEnv
  } = generateRunPreSQLPayload(formik, protocol, silentProperties, dataSourceTrailValues);
  return {
    datasetName,
    source: dataSource,
    isAzureEnv,
    dataBaseName,
    trialName,
    studyID,
    datasetDescription,
    queryString
  };
};

const queryJsonResult =
  '[{"STUDY_ID":1.0,"ITEM_GROUP_ID":108155960.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108155989.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108155999.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156006.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156010.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156019.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156021.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156062.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156064.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156066.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156084.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156106.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156123.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156127.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156130.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156147.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156180.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156193.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156198.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156215.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156218.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156221.0},{"STUDY_ID":1.0,"ITEM_GROUP_ID":108156232.0}]';

const NAME_REQUIRED = 'Dataset Name is required';
const UNDERSCORE_ALLOWED = 'Only alphanumeric and underscore is allowed';
const MAX_CHAR_ALLOWED = 'Maximum ${max} characters are allowed';
const validationSchema = yup.object({
  datasetName: yup
    .string()
    .required(NAME_REQUIRED)
    .max(15, MAX_CHAR_ALLOWED)
    .matches(/^[0-9a-zA-Z_]+$/g, UNDERSCORE_ALLOWED)
});

const AddNewDataSet = () => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSavedInDB, setIsSavedInDB] = useState(false);
  const [isEditQueryID, setIsEditQueryID] = useState('');
  const [queryColumns, setQueryColumns] = useState('');
  const [queryResult, setQueryResult] = useState();
  const [queryError, setQueryError] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isReadonly, setReadonly] = useState(true);

  const { studyObj } = useSelector((state) => state.AddStudyLibrary);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { name, type, dataSet, editInProgress, description } = studyObj;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const outputRef = useRef();
  const editPage =
    location.pathname === '/data-standard-library/edit-study-library/add-new-dataset';
  const { dataSourceTrailValues, silentProperties } = useSelector((state) => state.AddStudyLibrary);
  const formik = useFormik({
    initialValues: {
      datasetName: '',
      selectedDataSource: '',
      selectedTrialName: '',
      sqlQuery: '',
      datasetDescription: ''
    },
    validationSchema: !isReadonly ? validationSchema : null,
    onSubmit: (values) => {
      console.log(values);
      console.log(formik);
    },
    validateOnChange: true
  });
  const navigateToAddStudyLibrary = () => {
    navigate(
      editPage
        ? '/data-standard-library/edit-study-library'
        : '/data-standard-library/add-study-library'
    );
  };

  useEffect(() => {
    if (location?.state?.queryID) {
      setIsEditQueryID(location.state.queryID);
      setIsSavedInDB(location.state.isSavedInDB);
      formik.setValues({
        datasetName: location.state.datasetName,
        dataBaseName: location.state.dataBaseName,
        isAzureEnv: location.state.isAzureEnv,
        sqlQuery: location.state.queryString,
        datasetDescription: location.state.datasetDescription
      });
    }
    setReadonly(!!location?.state?.isReadonly);
  }, []);

  useEffect(() => {
    if (!isSaveDisabled) setIsSaveDisabled(true);
    if (!isReadonly) {
      setQueryColumns('');
      setQueryResult('');
      setQueryError();
    } else if (location?.state?.queryColumns && location?.state?.qCodeSourceJsonData) {
      setQueryColumns(location?.state?.queryColumns || '');
      setQueryResult(JSON.parse(location?.state?.qCodeSourceJsonData || '[]'));
    } else if (location?.state?.queryErrors) {
      setQueryError(location?.state?.queryErrors);
    }
  }, [formik.values, isReadonly]);

  const handleSave = () => {
    const queryID = uuidv4();
    let finalDataSet = [];
    const newDataSet = {
      queryID: queryID,
      queryColumns: queryColumns,
      isQueryActive: isEditQueryID ? location.state.isQueryActive : true,
      isSavedInDB: isSavedInDB,
      ...generateDatasetToSave(formik, protocol, silentProperties, dataSourceTrailValues)
    };
    if (isEditQueryID) {
      dataSet.forEach((item) => {
        if (item.queryID === isEditQueryID) {
          finalDataSet.push(newDataSet);
        } else finalDataSet.push(item);
      });
    } else {
      finalDataSet = [...dataSet, newDataSet];
    }
    setIsEditQueryID('');
    dispatch(
      getStudyFormObject({
        name: name,
        type: type,
        description: description,
        dataSet: finalDataSet,
        editInProgress: editInProgress
      })
    );
  };

  const handleRun = async () => {
    setLoading(true);
    try {
      const { selectedDataSource, sqlQuery } = formik.values;
      setQueryError('');
      const result = validatePreSQLQuery(sqlQuery, selectedDataSource, silentProperties?.cdrSchema);
      if (isReadonly || result.isValid) {
        const response = await dispatch(
          RunPreSQLQueries(
            generateRunPreSQLPayload(formik, protocol, silentProperties, dataSourceTrailValues)
          )
        ).then(unwrapResult);
        if (response?.data?.success) {
          if (response?.data?.querydata?.columns) {
            setQueryColumns(response.data.querydata.columns);
            const queryResult = JSON.parse(response?.data?.querydata?.queryJsonResult || '[]');
            console.log('queryResult', queryResult);
            setQueryResult(JSON.parse(response?.data?.querydata?.queryJsonResult || '[]'));
          }
          setIsSaveDisabled(!response?.data?.querydata?.columns);
        } else {
          setQueryError(response?.data?.message);
          // dispatch(showBanner({ variant: 'error', message: response.data.message }));
        }
      } else {
        setQueryError(result.errorMessage);
      }
      outputRef?.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      setQueryError(error?.message);
      console.log('Error while runPreSQL', error);
    }
    setLoading(false);
  };

  const handleReset = () => {
    formik.setValues({
      datasetName: '',
      selectedDataSource: '',
      selectedTrialName: '',
      sqlQuery: '',
      datasetDescription: ''
    });
    setIsSavedInDB(false);
    setIsEditQueryID('');
    navigate('.', { replace: true });
  };

  return (
    <Grid
      container
      spacing={2}
      // style={{ marginBottom: '2rem' }}
    >
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Button
          variant="secondary"
          size="small"
          icon={ChevronLeft}
          style={{
            marginRight: 10,
            color: '#0768FD',
            marginBottom: '10px',
            marginLeft: '20px'
          }}
          onClick={() => {
            navigateToAddStudyLibrary();
          }}>
          {editPage ? 'Return to Edit Study Library' : 'Return to Add Study Library'}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          gutterBottom
          style={{
            color: '#444444',
            fontFamily: 'Proxima Nova',
            fontSize: '20px',
            fontWeight: 600,
            letterSpacing: 0,
            marginLeft: '20px'
          }}>
          {isReadonly ? 'Import Custom SQL' : isEditQueryID ? 'Edit SQL Query' : 'Add SQL Query'}
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          style={{
            color: '#444444',
            fontFamily: 'Proxima Nova',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 0,
            marginLeft: '20px'
          }}>
          Provide a name for your new dataset, choose it’s data source and enter your SQL Query
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '-1.8rem' }}>
        <PreSQLAddNewDataset
          formik={formik}
          handleRun={handleRun}
          isLoading={isLoading}
          isSavedInDB={isSavedInDB}
          isReadonly={isReadonly}
        />
      </Grid>
      <Grid item xs={12} sx={{ scrollMarginTop: '116px' }} ref={outputRef}>
        <QueryOutput
          queryColumns={queryColumns}
          queryResult={queryResult}
          queryError={queryError}
        />
      </Grid>
      {!isReadonly && (
        <Card
          style={{
            flex: 'auto',
            padding: '1rem',
            marginTop: '0.5rem',
            position: 'sticky',
            bottom: 0,
            zIndex: 2
          }}>
          <Box
            style={{
              display: 'flex',
              marginLeft: '12px'
            }}>
            <Grid
              style={{
                display: 'flex'
              }}>
              <Button
                variant="secondary"
                size={'small'}
                onClick={() => {
                  navigateToAddStudyLibrary();
                }}>
                {' '}
                Cancel
              </Button>
            </Grid>

            <Grid
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end'
              }}>
              <Button
                sx={{ marginRight: '8px' }}
                variant="secondary"
                disabled={isSaveDisabled}
                size={'small'}
                onClick={() => {
                  handleSave();
                  handleReset();
                }}>
                Save and add another one
              </Button>
              <Button
                variant="primary"
                disabled={isSaveDisabled}
                size={'small'}
                onClick={() => {
                  handleSave();
                  navigateToAddStudyLibrary();
                }}>
                Save Dataset
              </Button>
            </Grid>
          </Box>
        </Card>
      )}
    </Grid>
  );
};

export default AddNewDataSet;
