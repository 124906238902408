/* eslint-disable */
import { Box, Collapse, Grid, IconButton } from '@mui/material';
import { Typography } from 'apollo-react';
import Drag from 'apollo-react-icons/Drag';
import Trash from 'apollo-react-icons/Trash';
import Switch from 'apollo-react/components/Switch';
import TextField from 'Components/Controls/TextField';
import CustomTextField from 'Components/CustomeTextField/CustomeTextField';
import { useEffect, useRef, useState } from 'react';
import { generateErrorString, getHtmlString, uuidv4 } from 'Utils';
import { makeStyles } from '@mui/styles';
import StatusNegative from 'apollo-react-icons/StatusNegative';
import CloseCircle from 'apollo-react-icons/CloseCircle';

const useStyles = makeStyles({
  switch: {},
  textField: {
    '& input': {
      border: 'none',
      backgroundColor: '#FFFF'
    }
  },
  textField1: {
    padding: '0rem 1rem'
  },
  row: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderBottom: '1px solid #4441'
  },
  column: {
    padding: '1rem'
  },
  trshIcon: {
    display: 'flex',
    justifyContent: 'end'
  },
  rowColapsed: {},
  expressionCol: {
    height: '100%',
    paddingBottom: '6px',
    fontWeight: 'bold',
    fontFamily: 'Courier New',
    display: 'flex'
  },
  StatusNegativeIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  plusIcon: {
    display: 'flex',
    justifyContent: 'center'
  },
  collapse: {
    width: '100%'
  },
  dragTopRow: {
    width: '100%',
    borderTop: '3px solid #0768fd'
  },
  dragBottomRow: {
    width: '100%',
    borderBottom: '3px solid #0768fd'
  },
  hidden: {
    display: 'none'
  }
});

const fieldStyles = {
  style: {
    margin: '3px 0px'
  }
};

const Variable = (props) => {
  const {
    index,
    variableName,
    expressions,
    label,
    status,
    variableRuleSetID,
    onDelete,
    rowCount,
    editRow,
    preview,
    ruleSetValidationResult,
    handleCaretPositionChange,
    onMoveVariableRow,
    rowData
  } = props;

  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [variableNameError, setVariableNameError] = useState({});
  const [expressionErrors, setExpressionErrors] = useState({});
  const [labelError, setLabelError] = useState({});
  const [cordinates, setCordinates] = useState({});
  const [draggable, setDraggable] = useState(false);
  const [keyDraggable, setKeyDraggable] = useState(false);
  const [specialCharExist, setSpecialCharExist] = useState({});

  const handleAddExpression = () => {
    const newExpressions = [...expressions, { id: uuidv4(), expression: '' }];
    editRow(newExpressions, 'expressions', variableRuleSetID);
  };

  const handleDeleteExpression = (idx) => {
    const updatedExpressions = [...expressions];
    updatedExpressions.splice(idx, 1);
    editRow(updatedExpressions, 'expressions', variableRuleSetID);
  };

  useEffect(() => {
    setLoaded(true);
  }, []);
  const rowref = useRef();
  const [dragPosition, setDragPosition] = useState('');
  const handleDelete = () => {
    setLoaded(false);
    setTimeout(() => onDelete(variableRuleSetID), 600);
  };

  useEffect(() => {
    const getVariableNameError =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isColumnValid(variableRuleSetID, 'variableName')
        : variableNameError;
    const getLabelError =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isColumnValid(variableRuleSetID, 'label')
        : labelError;
    const getExpressionErrors =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isColumnValid(variableRuleSetID, 'expressions')
        : expressionErrors;
    const getCordinates =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isExpressionValid(variableRuleSetID)
        : cordinates;
    const specialCharValue =
      ruleSetValidationResult && Object.keys(ruleSetValidationResult).length > 0
        ? ruleSetValidationResult.isSpecialCharPresent()
        : specialCharExist;

    setSpecialCharExist(specialCharValue);
    setVariableNameError(getVariableNameError);
    setLabelError(getLabelError);
    setExpressionErrors(getExpressionErrors);
    setCordinates(getCordinates);
  }, [ruleSetValidationResult]);

  const getErrorText = (data) => {
    return data.mandatory ? data.mandatory : data.unique || data.message;
  };

  const handleOnDragOver = (e) => {
    e.dataTransfer.dropEffect = 'move';
    const {
      nativeEvent: { offsetY }
    } = e;
    const offsetHeight = rowref.current.offsetHeight;
    if (offsetY > offsetHeight / 2) {
      setDragPosition('bottom');
    } else if (offsetY < offsetHeight / 2) {
      setDragPosition('top');
    }
    e.preventDefault();
  };

  const handleOnDragEnd = (e) => {
    setDraggable(false);
    setDragPosition('');
    const {
      target: {
        parentElement: { childNodes },
        offsetTop,
        offsetHeight
      },
      nativeEvent: { offsetY }
    } = e;
    const currentRowPosition = offsetTop + offsetY;
    const rowPositions = [];
    for (let child of childNodes) {
      rowPositions.push(child.offsetTop + child.offsetHeight / 2);
      child.classList.remove(classes.dragTopRow, classes.dragBottomRow);
      child.classList.add(classes.collapse);
    }

    let moveToIndex =
      offsetY > offsetHeight / 2
        ? rowPositions.reduce((previousIndex, rowPosition, rowIndex) => {
            if (currentRowPosition > rowPosition) {
              return rowIndex;
            }
            return previousIndex;
          }, index)
        : rowPositions.reduceRight((previousIndex, rowPosition, rowIndex) => {
            if (currentRowPosition < rowPosition) {
              return rowIndex;
            }
            return previousIndex;
          }, index);

    console.log('moveToIndex', offsetY, index, moveToIndex);
    if (index !== moveToIndex) onMoveVariableRow(index, moveToIndex);
    e.target.style.border = '2px solid #0768fd';
    setTimeout(() => {
      e.target.style.opacity = 1;
      e.target.style.border = '';
    }, 600);
  };

  const handleTransition = (e) => {
    e.target.parentElement.parentElement.style.border = '2px solid #0768fd';
    setTimeout(() => {
      e.target.parentElement.parentElement.style.border = '';
    }, 1000);
  };

  return (
    <Collapse
      in={loaded}
      draggable={draggable}
      className={
        dragPosition === 'bottom'
          ? classes.dragBottomRow
          : dragPosition === 'top'
          ? classes.dragTopRow
          : classes.collapse
      }
      onDragEnd={handleOnDragEnd}
      onDragLeave={() => setDragPosition('')}
      onDragOver={(e) => handleOnDragOver(e)}
      ref={rowref}>
      <Box display={'flex'} flexDirection={'row'}>
        {!preview && (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{
              borderRight: '1px solid #4441',
              borderBottom: '1px solid #4441',
              background: 'white',
              width: '4%'
            }}>
            {rowCount > 1 ? (
              <Drag
                tabIndex={rowCount <= 1 ? '-1' : '0'}
                onMouseDown={() => setDraggable(true)}
                onMouseUp={() => setDraggable(false)}
                onClick={() => setKeyDraggable(true)}
                onFocus={() => setKeyDraggable(true)}
                onBlur={() => setKeyDraggable(false)}
                onKeyDown={
                  keyDraggable
                    ? (e) => {
                        if (e.key === 'ArrowUp' && index !== 0) {
                          let moveToIndex = index - 1;
                          onMoveVariableRow(index, moveToIndex);
                          handleTransition(e);
                        } else if (e.key === 'ArrowDown' && index < rowData.length - 1) {
                          let moveToIndex = index + 1;
                          onMoveVariableRow(index, moveToIndex);
                          handleTransition(e);
                        }
                      }
                    : undefined
                }
                style={{
                  size: 'large',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '3%',
                  background: draggable || keyDraggable ? '#d9d9d9' : 'white',
                  outline: 0,
                  cursor: draggable ? 'grabbing' : 'grab'
                }}
              />
            ) : (
              <Drag
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '3%',
                  color: '#b5b5b5'
                }}></Drag>
            )}
          </Box>
        )}
        <Grid
          container
          className={classes.row}
          style={{ width: preview ? '100%' : '96%' }}
          onMouseDown={() => setDraggable(false)}>
          <Grid xs={12} className={classes.column}>
            <Box display={'flex'} flexDirection={'row'} width={'100%'}>
              <Grid xs={1}>
                <Typography
                  style={{
                    color: '#444444',
                    display: 'block',
                    fontSize: '14px'
                  }}>
                  Active
                </Typography>
                <Switch
                  className={classes.switch}
                  checked={status}
                  onChange={(e) => editRow(!status, 'status', variableRuleSetID)}
                  size="small"
                  disabled={preview}
                />
              </Grid>
              <Grid xs={3}>
                <TextField
                  autoFocus={true}
                  size="small"
                  value={variableName}
                  onChange={(e) => editRow(e.target.value, 'variableName', variableRuleSetID)}
                  label={'Variable Name'}
                  className={[classes.textField1, preview && classes.textField]}
                  InputProps={{ readOnly: preview }}
                  disabled={preview}
                  style={{ margin: '0px 0 0 0' }}
                  {...(Object.keys(variableNameError).length > 0
                    ? {
                        helperText:
                          getErrorText(variableNameError) || getErrorText(specialCharExist),
                        error: true
                      }
                    : {})}
                />
              </Grid>

              <Grid xs={3}>
                <TextField
                  value={label}
                  size="small"
                  onChange={(e) => editRow(e.target.value, 'label', variableRuleSetID)}
                  label={'Label Name'}
                  className={[classes.textField1, preview && classes.textField]}
                  InputProps={{ readOnly: preview }}
                  disabled={preview}
                  style={{ margin: preview ? '0px' : '0px 0 0 0' }}
                  {...(Object.keys(labelError).length > 0
                    ? {
                        helperText: getErrorText(labelError),
                        error: true
                      }
                    : {})}
                />
              </Grid>
              <Grid xs={5} className={classes.trshIcon}>
                <Grid xs={preview ? 0 : 1} className={[preview && classes.hidden]}>
                  <IconButton
                    size="small"
                    data-testid="delete-btn"
                    onClick={handleDelete}
                    disabled={rowCount <= 1}>
                    <Trash />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid container className={classes.StatusNegativeIcon}>
            <Grid xs={12} style={{ padding: '1rem 1rem 0rem 1rem' }}>
              {expressions?.map((item, inputIndex) => (
                <Box key={item.id} className={classes.expressionCol}>
                  <Grid container>
                    <Grid item xs={11}>
                      <CustomTextField
                        id={item.id}
                        value={item.expression}
                        onChange={(value) => {
                          const updatedExpressions = [...expressions];
                          updatedExpressions[inputIndex] = { id: item.id, expression: value };
                          editRow(updatedExpressions, 'expressions', variableRuleSetID);
                        }}
                        onCaretPositionchange={(pos) =>
                          handleCaretPositionChange(pos, variableRuleSetID, index, inputIndex)
                        }
                        name={`expressions-${inputIndex}`}
                        data-testid={`expression-input-${inputIndex}`}
                        disabled={preview}
                        helperText={
                          expressionErrors[item.id] && !expressionErrors[item.id].isValid
                            ? getErrorText(expressionErrors[item.id])
                            : ''
                        }
                        error={
                          expressionErrors[item.id] ? !expressionErrors[item.id].isValid : false
                        }
                        customProps={{
                          style: !preview
                            ? {
                                borderRadius: '7px'
                              }
                            : {
                                backgroundColor: '#F2F2F2',
                                border: '1px solid #D9D9D9',
                                borderRadius: '7px'
                              },
                          placeholder: 'abc'
                        }}>
                        {expressionErrors[item.id] && !expressionErrors[item.id].isValid
                          ? generateErrorString(
                              `${item.expression}`,
                              cordinates[item.id]?.start || 0,
                              cordinates[item.id]?.end || 0
                            )
                          : getHtmlString(`${item.expression}`, [], '', 2)}
                      </CustomTextField>
                    </Grid>
                    <Grid item xs={1} className={[preview && classes.hidden]}>
                      <IconButton
                        size="small"
                        data-testid="status-btn"
                        disabled={expressions?.length <= 1}
                        onClick={() => handleDeleteExpression(inputIndex)}>
                        <StatusNegative style={{ color: '#BD0100' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Grid xs={12} className={[classes.plusIcon, preview && classes.hidden]}>
            <IconButton
              size="small"
              data-testid="plus-btn"
              onClick={handleAddExpression}
              style={{ transform: 'rotate(45deg)', color: '#0768fd' }}>
              <CloseCircle style={{ color: '#0557D5' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
};

export default Variable;
