/* eslint-disable */
import { makeStyles } from '@mui/styles';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import { primaryLight } from 'apollo-react/colors';
import Button from 'apollo-react/components/Button';
import Checkbox from 'apollo-react/components/Checkbox';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import Table from 'apollo-react/components/Table';
import { unwrapResult } from '@reduxjs/toolkit';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import classNames from 'classnames';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Loader from 'Components/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetWorkflowModel } from 'Redux/Service/RuleEditorService';
import {
  getStudiesSources,
  saveConfigConnections,
  saveTestConnections
} from 'Redux/Service/StudyConfigLibrary';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import { closeLoader, showLoader } from 'Redux/Slice/LoaderSlice';
import { getProtocol } from 'Redux/Slice/StudyLibrarySlice';
import { uuidv4 } from 'Utils';
import useDisplayName from 'Utils/useDisplayName';
import { setSDHDataProductWorkflow } from 'Redux/Slice/SDHDataProductSlice';

const Cell = ({ row, column }) => {
  const getDisplayName = useDisplayName();
  return (
    <div style={{ paddingTop: row.editMode ? 12 : 0 }}>{getDisplayName(row[column.accessor])}</div>
  );
};

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};

const makeEditableSelectCell =
  () =>
  ({ row, column: { accessor: key } }) => {
    const databaseOptions = row.options.map((option) => {
      return option.databaseName;
    });
    return (
      <Select
        key={row.uuid}
        size="small"
        fullWidth
        canDeselect={false}
        value={row.databaseName}
        onChange={(e) => {
          row.editRow(row.uuid, key, e.target.value, row);
        }}
        {...fieldStyles}>
        {databaseOptions.map((option, index) => (
          <MenuItem key={`${option}-${index}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };

const styles = {
  selected: {
    '& td': {
      backgroundColor: primaryLight
    }
  },
  error: {
    '& td': {
      backgroundColor: 'rgba(226,0,0,0.06)'
    }
  }
};

const useStyles = makeStyles(styles);

const TableBulkEdit = () => {
  const [rows, setRows] = useState([]);
  const [editedRow, setEditedRow] = useState({});
  const [resetFlag, setResetFlag] = useState(false);
  const [bulkEditMode, setBulkEditMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDataRows, setSelectedDataRows] = useState([]);
  const [bulkDeleteMode, setBulkDeleteMode] = useState(false);
  const [testConnection, setTestConnection] = useState(false);
  const [saveFlag, setSaveFlag] = useState(false);
  const [saveSDHEnabled, setSaveSDHEnabled] = useState(false);
  const [saveSDHTriggered, setSaveSDHTriggered] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateData = useRef(testConnection);
  const saveFlagCheck = useRef(saveFlag);
  const [isCheck, setIsCheck] = useState();
  const [copySelectedRows, setCopySelectedRows] = useState([]);
  const [copySelectedDataRows, setCopySelectedDataRows] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [saveTriggered, setSaveTriggered] = useState(undefined);
  const [EDCUUID, setEDCUUID] = useState('');
  const [EDCFlag, setEDCFlag] = useState(false);
  const [configDBFlag, setConfigDBFlag] = useState(true);

  const { workFlowModel } = useSelector((state) => {
    return state.SDHDataProduct;
  });

  const { protocol, checkboxEnable, studyLibraries, disableCheck } = useSelector(
    (state) => state.StudyLibraryData
  );
  const loading = useSelector((state) => state.LoaderData.loading);

  async function getWorkflow() {
    const data = await dispatch(GetWorkflowModel('SDHDataProduct')).then(unwrapResult);
    const { success, functionData, message } = data || {};
    if (success) {
      const sortedFD = functionData?.length > 0 && functionData.sort((a, b) => a.order - b.order);
      dispatch(setSDHDataProductWorkflow(sortedFD));
    } else {
      dispatch(showBanner({ variant: 'error', message }));
    }
  }

  useEffect(() => {
    getWorkflow();
  }, []);

  useEffect(() => {
    if (studyLibraries && studyLibraries.data?.studyLibraries.length > 0) {
      setIsCheck(false);
    } else {
      setIsCheck(true);
    }
  }, [studyLibraries]);

  useEffect(() => {
    let isRequiredFlag = false;
    let allConnected = true;
    selectedRows.map((item) => {
      rows.map((el) => {
        if (el.uuid === item && !el.isConnected) {
          allConnected = false;
        }
        if (el.uuid === item && el.is_SDH && el.isConnected) {
          isRequiredFlag = true;
        }
      });
    });
    setSaveSDHEnabled(isRequiredFlag && allConnected);
  }, [rows, selectedRows]);

  const classes = useStyles();
  const studyData = [];
  const STUDY_LIBRARY_PATH = '/data-standard-library/study-library';
  const NON_ECRF_ADD_DP_PATH = `/product-designer/${workFlowModel[0]?.moduleName}`;
  const NOT_CONNECTED = 'Not Connected';

  const resetConnections = () => {
    setResetFlag(true);
    navigate(STUDY_LIBRARY_PATH);
  };

  useEffect(() => {
    if (saveSDHTriggered) {
      handleTestConnection();
    }
  }, [saveSDHTriggered]);

  const handleSaveCreateSDHDp = () => {
    setSaveSDHTriggered(true);
  };

  const checkButton = (data) => {
    let isConnected = true;
    let isSaved = true;
    data.map((item) => {
      if (!item.isConnected) {
        isConnected = false;
      } else if (item.isConnected && !item.isSaved) {
        isSaved = false;
      }
    });
    return { isConnected, isSaved };
  };

  const CustomButtonHeader = ({ selectedDataRows }) => {
    const obj = checkButton(selectedDataRows);
    if (!obj.isSaved) {
      setSaveFlag(true);
    }
    return (
      <div>
        {!disableCheck && (
          <>
            <Button
              size="small"
              // variant="primary"
              // disabled={selectedDataRows.length === 0}
              onClick={resetConnections}
              style={{ marginRight: '4px' }}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="primary"
              disabled={
                saveTriggered ||
                (configDBFlag
                  ? true
                  : !isCheck
                  ? obj.isConnected && obj.isSaved
                  : obj.isConnected && obj.isSaved && isDisabled)
              }
              onClick={handleTestConnection}>
              {saveFlag || !obj.isSaved ? 'Save' : 'Test Connection'}
            </Button>
            {saveSDHEnabled && (saveFlag || !obj.isSaved) ? (
              <Button
                size="small"
                variant="primary"
                style={{ marginLeft: '4px' }}
                onClick={handleSaveCreateSDHDp}>
                Save & Create SDH Data Product
              </Button>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    );
  };

  const saveConnectionSetting = () => {
    setSaveTriggered(true);
    let saveArray = [];
    let payload = {};
    const copyOfRowData = JSON.parse(JSON.stringify(rows));
    copyOfRowData.map((el) => {
      if (
        ((el.connectionMessage === 'Connected' && el.isActive !== 'Y') ||
          (el.connection === 'Connected' && el.isActive === 'Y')) &&
        selectedRows.includes(el.uuid)
      ) {
        delete el.connection;
        delete el.options;
        delete el.sourceName;
        delete el.password;
        delete el.uuid;
        delete el.connectionMessage;
        delete el.databaseName;
        delete el.isDisable;
        delete el.is_SDH;
        el.isActive = 'Y';
        el.protocolNumber = protocol.protocolNumber;
        el.auditType = 'INSERT';
        el.createdDate = new Date().toISOString();
        el.auditDate = new Date().toISOString();
        saveArray.push(el);
      }
    });
    if (saveArray.length > 0) {
      payload.studyDataSource = saveArray;
      let saveConnections = saveConfigConnections(payload);
      saveConnections
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.success) {
            dispatch(getProtocol({ ...protocol, isDataSouceConfigured: true }));
            setSaveFlag(false);
            saveSDHTriggered ? navigate(NON_ECRF_ADD_DP_PATH) : navigate(STUDY_LIBRARY_PATH);
            dispatch(
              showBanner({ variant: 'success', message: response.message, propagate: true })
            );
          } else {
            setSaveFlag(false);
            dispatch(showBanner({ variant: 'error', message: response.message }));
          }
          setSaveSDHTriggered(false);
          setSaveTriggered(false);
          return true;
        })
        .catch((e) => {
          setSaveSDHTriggered(false);
          setSaveTriggered(false);
          console.log('Error while saving source config', e);
        });
      dispatch(closeLoader());
    } else {
      dispatch(showBanner({ variant: 'error', message: 'Nothing to Save' }));
      setSaveFlag(false);
      dispatch(closeLoader());
      setSaveSDHTriggered(false);
      setSaveTriggered(false);
      return true;
    }
  };

  const checkRefreshConnection = () => {
    setSaveFlag(saveFlagCheck.current);
    return true;
  };

  const handleTestConnection = async (e, getFlag) => {
    dispatch(showLoader());
    setResetFlag(false);
    let saveConnections = false;
    let checkRefresh = false;
    if (getFlag) {
      checkRefresh = await checkRefreshConnection();
    }

    if (!checkRefresh) {
      if (saveFlag) {
        saveConnections = await saveConnectionSetting();
      }
    }

    let newArr = [];
    selectedDataRows.forEach((row) => {
      let keys = Object.keys(row);
      let flag = keys.every((key) => row[key] !== undefined && row[key] !== '');
      if (flag) {
        newArr.push(row);
      }
    });

    setTestConnection(stateData);
    if (!saveConnections) {
      let payload = { protocolNumber: protocol.protocolNumber };
      payload.masterDataSources = newArr.map((item) => {
        const copyObj = { ...item };
        delete copyObj.uuid;
        delete copyObj.isSaved;
        delete copyObj.isConnected;
        delete copyObj.is_SDH;
        return copyObj;
      });
      const testConnections = saveTestConnections(payload);
      testConnections
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.success) {
            const modifiedRows = JSON.parse(JSON.stringify(rows));
            response.masterDataSources.map((el) => {
              modifiedRows.map((el1) => {
                if (el1.sourceName === el.sourceName) {
                  return el1.options.map((option) => {
                    if (option.dataSourceID === el.dataSourceID) {
                      option.isActive = 'Y';
                      el1.connection = el.connectionStatus ? 'Connected' : NOT_CONNECTED;
                      el1.dataSourceID = el.dataSourceID;
                      el1.isConnected = true;
                      el1.connectionMessage = el.connectionMessage;
                    }
                  });
                }
              });
            });
            setSaveFlag(true);
            setRows(modifiedRows);
            selectedDataRows.forEach((eachRow) => {
              rows.forEach((row) => {
                if (
                  row.sourceName === 'CONFIG_DB' &&
                  row.isConnected === true &&
                  eachRow.sourceName === 'CONFIG_DB'
                ) {
                  delete eachRow.isConnected;
                  delete eachRow.isSaved;
                }
              });
            });
            setTestConnection(true);
            dispatch(closeLoader());
          } else {
            dispatch(closeLoader());
          }
        });
    }
  };

  const CheckboxCell = (props) => {
    return (
      <div style={{ width: 16, marginTop: -2 }}>
        <Checkbox
          checked={selectedRows.includes(props.row.uuid)}
          onChange={(e) => handleSelectRow(props.row, e)}
          disabled={
            checkboxEnable &&
            !isCheck &&
            ((props.row.userID === '' && props.row.password === '') ||
              props.row.connection === NOT_CONNECTED ||
              props.row.isDisable)
          }
        />
      </div>
    );
  };

  const checkData = (id, data) => {
    return data.findIndex((item) => item.dataSourceID === id);
  };

  const ConnectionCell = (props) => {
    if (props.row.connection && props.row.connection === NOT_CONNECTED) {
      return (
        <>
          <div style={{ display: 'flex' }}>
            <Tooltip title={props.row.connectionMessage} subtitle={'Call Product Support'}>
              {/* <IconButton color="primary"> */}
              <StatusExclamation style={{ color: 'red' }} />
              {/* </IconButton> */}
            </Tooltip>
            <Typography
              variant="title2"
              style={{
                fontSize: '14px',
                marginTop: '4px',
                marginLeft: '4px'
              }}>
              {'Connection Error'}
            </Typography>
          </div>
          <Typography
            variant="title2"
            style={{
              fontSize: '14px',
              marginTop: '4px',
              marginLeft: '4px'
            }}>
            <Button
              size="small"
              // variant="primary"
              onClick={(e) => handleTestConnection(e, true)}
              style={{ marginLeft: '18px' }}>
              Refresh
            </Button>
          </Typography>
        </>
      );
    } else if (
      props.row.connection &&
      props.row.isConnected &&
      props.row.connection === 'Connected' &&
      checkData(props.row.dataSourceID, props.row.selectedDataRows) !== -1
    ) {
      return (
        <div style={{ display: 'flex' }}>
          <StatusCheck style={{ color: '#00C221' }} />
          <Typography
            variant="title2"
            style={{
              fontSize: '14px',
              marginTop: '4px',
              marginLeft: '4px'
            }}>
            {'Connected'}
          </Typography>
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Standard Libraries',
          path: STUDY_LIBRARY_PATH
        },
        {
          title: 'Source Configuration',
          path: '/data-standard-library/source-configuration'
        }
      ])
    );

    let payload = {
      protocol: protocol.protocolNumber,
      isDataSourceConfigured:
        protocol.isDataSouceConfigured === undefined ? true : protocol.isDataSouceConfigured
    };
    if (!testConnection) {
      const studyConfigData = getStudiesSources(payload);
      studyConfigData.then((response) => {
        if (response.data.success) {
          response.data.masterDataSources.length > 0 &&
            response.data.masterDataSources.forEach((ele) => {
              const { sourceName, is_SDH, ...rest } = ele;
              const index = studyData.findIndex((item) => item.sourceName === sourceName);
              if (index === -1) {
                const newObj = {
                  sourceName: sourceName,
                  options: [{ ...rest, sourceName, isActive: 'N' }],
                  userID: '',
                  password: '',
                  databaseName: '',
                  uuid: uuidv4(),
                  connection: '',
                  isActive: '',
                  isConnected: '',
                  is_SDH: is_SDH
                };
                studyData.push(newObj);
              } else {
                studyData[index].options.push({ ...rest, sourceName, isActive: 'N' });
              }
            });
          const studyDataSources = response.data.studyDataSources;
          studyDataSources &&
            studyDataSources.length > 0 &&
            getStudyDataSource(studyDataSources, studyData);

          setRows(studyData);
        } else {
          dispatch(showBanner({ variant: 'error', message: response.data.message }));
        }
      });
    } else {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].connection === 'Connected') {
          setSaveFlag(true);
          return true;
        }
      }
      setTestConnection(true);
    }
    dispatch(closeLoader());

    selectedDataRows.forEach((row) => {
      if (row.sourceName === 'CONFIG_DB' && row.isConnected) {
        setConfigDBFlag(false);
      }
    });
  }, [protocol.protocolNumber, testConnection, resetFlag]);

  const getStudyDataSource = (studySourcesData, studyData) => {
    const uuidArray = [];
    const selectRows = [];
    studySourcesData.forEach((studySource) => {
      studyData.forEach((masterSource) => {
        masterSource.options.forEach((option) => {
          if (studySource.datasourceID === option.dataSourceID) {
            if (studySource.isActive === 'Y') {
              masterSource.isActive = 'Y';
              option.isActive = 'Y';
              masterSource.databaseName = option.databaseName;
              masterSource.dataSourceID = option.dataSourceID;
              masterSource.userID = option.userID;
              masterSource.password = option.password && '*****';
              masterSource.connection = 'Connected';
              masterSource.isDisable = true;
              masterSource.isConnected = true;
              masterSource.is_SDH = masterSource.is_SDH;
              option.isSaved = true;
              uuidArray.push(masterSource.uuid);
              selectRows.push({
                databaseName: option.databaseName,
                dataSourceID: option.dataSourceID,
                userID: option.userID,
                password: option.password.replace('\\', ''),
                uuid: masterSource.uuid,
                sourceID: option.sourceID,
                sourceName: option.sourceName,
                isConnected: masterSource.connection ? true : false,
                is_SDH: masterSource.is_SDH,
                isSaved: true
                // connectionMessage: o,
                // connectionStatus: true
                // isDisable: masterSource.isDisable
              });
            }
          }
        });
      });
    });
    setSelectedRows(uuidArray);
    setCopySelectedRows(uuidArray);
    setSelectedDataRows(selectRows);
    setCopySelectedDataRows(selectRows);
  };

  const columns = [
    !disableCheck && {
      header: 'Select',
      accessor: '',
      customCell: CheckboxCell,
      fixedWidth: false
    },

    {
      header: 'Source',
      accessor: 'sourceName',
      customCell: Cell,
      fixedWidth: false
    },
    {
      header: 'Database',
      accessor: 'databaseName',
      //   sortFunction: compareStrings,
      customCell: makeEditableSelectCell(),
      fixedWidth: false
    },
    {
      header: 'Username',
      accessor: 'userID',
      fixedWidth: false
    },
    {
      header: 'Password',
      accessor: 'password',
      fixedWidth: false
    },
    {
      header: 'Connection',
      accessor: 'connection',
      //sortFunction: compareDates
      customCell: ConnectionCell,
      fixedWidth: false
    }
  ];

  const editRow = (uuid, key, value, row) => {
    setSaveFlag(false); // Test Connection //
    const copyRows = [...rows];
    const copySelectDataRows = [...selectedDataRows];
    const index = copyRows.findIndex((row) => {
      return row.uuid === uuid;
    });

    if (index !== -1) {
      const databaseoptions = copyRows[index].options;
      const databaseIndex = databaseoptions.findIndex((databaseoption) => {
        return databaseoption.databaseName === value;
      });

      copyRows[index]['connection'] =
        databaseoptions[databaseIndex].isActive && databaseoptions[databaseIndex].isActive === 'Y'
          ? 'Connected'
          : '';
      copyRows[index]['isDisable'] =
        databaseoptions[databaseIndex].isActive && databaseoptions[databaseIndex].isActive === 'Y'
          ? true
          : false;
      copyRows[index]['password'] = databaseoptions[databaseIndex].password && '*******';
      copyRows[index]['userID'] = databaseoptions[databaseIndex].userID;
      copyRows[index]['databaseName'] = value;
      copyRows[index]['dataSourceID'] = databaseoptions[databaseIndex].dataSourceID;
      copyRows[index]['isConnected'] =
        databaseoptions[databaseIndex].isActive && databaseoptions[databaseIndex].isActive === 'Y'
          ? true
          : false;

      if (selectedRows.includes(uuid)) {
        let selectedIndex = selectedDataRows.findIndex(
          (selectedData) => selectedData.uuid === uuid
        );
        if (selectedIndex !== -1) {
          copySelectDataRows[selectedIndex].databaseName = value;
          copySelectDataRows[selectedIndex].dataSourceID =
            databaseoptions[databaseIndex].dataSourceID;
          copySelectDataRows[selectedIndex].password =
            databaseoptions[databaseIndex].password &&
            databaseoptions[databaseIndex].password.replace('\\', '');
          copySelectDataRows[selectedIndex].sourceID = databaseoptions[databaseIndex].sourceID;
          copySelectDataRows[selectedIndex].userID = databaseoptions[databaseIndex].userID;
          copySelectDataRows[selectedIndex].sourceName = copyRows[index]['sourceName'];
          copySelectDataRows[selectedIndex].uuid = copyRows[index]['uuid'];
          copySelectDataRows[selectedIndex].isConnected =
            databaseoptions[databaseIndex].isActive &&
            databaseoptions[databaseIndex].isActive === 'Y'
              ? true
              : false;
          copySelectDataRows[selectedIndex].isSaved = databaseoptions[databaseIndex]['isSaved']
            ? true
            : false;
        }
      }
    }
    setRows(copyRows);

    const checkAnyOtherSource = (row) => row.sourceName !== '' && row.databaseName !== '';

    selectedDataRows.some(checkAnyOtherSource) ? setConfigDBFlag(false) : setConfigDBFlag(true);
    setSelectedDataRows(copySelectDataRows);
  };
  useEffect(() => {
    if (selectedDataRows.length >= 1) {
      const checkAnyOtherSource = (row) => row.sourceName !== '' && row.databaseName !== '';

      selectedDataRows.some(checkAnyOtherSource) ? setConfigDBFlag(false) : setConfigDBFlag(true);
    } else {
      setConfigDBFlag(true);
    }
  }, [selectedDataRows]);

  const handleSelectRow = (row, e) => {
    setEDCUUID(row.uuid);
    setEDCFlag(false);

    // No Study Library Present
    if (isCheck) {
      if (selectedRows.includes(row.uuid)) {
        setIsDisabled(false);
        setSelectedRows(selectedRows.filter((id) => id !== row.uuid));
        setSelectedDataRows(
          selectedDataRows.filter((item) => {
            if (item.sourceName !== '') {
              return item.databaseName !== row.databaseName;
            }
          })
        );
      } else {
        setIsDisabled(false);
        setSaveFlag(false);
        setSelectedRows([...selectedRows, row.uuid]);
        const newObj = {
          databaseName: '',
          userID: '',
          sourceName: '',
          sourceID: '',
          dataSourceID: '',
          password: '',
          uuid: row.uuid
        };
        const dataObject = { ...row };
        dataObject.options.forEach((el) => {
          if (
            row.databaseName &&
            el.sourceName === row.sourceName &&
            el.databaseName === row.databaseName
          ) {
            newObj.databaseName = el.databaseName;
            newObj.dataSourceID = el.dataSourceID;
            newObj.userID = el.userID;
            newObj.password = el.password.replace('\\', '');
            newObj.uuid = row.uuid;
            newObj.sourceID = el.sourceID;
            newObj.is_SDH = dataObject.is_SDH;
            newObj.sourceName = el.sourceName;
          }
          if (el.connectionMessage && el.connectionStatus) {
            newObj.connectionMessage = el.connectionMessage;
            newObj.connectionStatus = el.connectionStatus;
          }
          // return newObj;
        });
        if (newObj && Object.keys(newObj).length > 0) {
          setSelectedDataRows([...selectedDataRows, newObj]);
        }
      }
      // }
    } else {
      //study library present
      if (selectedRows.includes(row.uuid)) {
        setSaveFlag(false);
        setSelectedRows(selectedRows.filter((id) => id !== row.uuid));
        setSelectedDataRows(
          selectedDataRows.filter((item) => item.databaseName !== row.databaseName)
        );
        const index = rows.findIndex((item) => item.uuid === row.uuid);
        let copyRows = [...rows];
        copyRows[index].connection = '';
        setRows(copyRows);
      } else {
        setSaveFlag(false);
        setSelectedRows([...selectedRows, row.uuid]);
        const newObj = { uuid: row.uuid };
        const dataObject = { ...row };
        dataObject.options.map((el) => {
          if (
            row.databaseName &&
            el.sourceName === row.sourceName &&
            row.databaseName === el.databaseName
          ) {
            newObj.databaseName = el.databaseName;
            newObj.dataSourceID = el.dataSourceID;
            newObj.userID = el.userID;
            newObj.password = el.password.replace('\\', '');
            newObj.uuid = row.uuid;
            newObj.sourceID = el.sourceID;
            newObj.is_SDH = dataObject.is_SDH;
            newObj.sourceName = el.sourceName;
          }
          if (el.connectionMessage && el.connectionStatus) {
            newObj.connectionMessage = el.connectionMessage;
            newObj.connectionStatus = el.connectionStatus;
          }
          return newObj;
        });
        setSelectedDataRows([...selectedDataRows, newObj]);
      }
    }

    if (row.sourceName === 'EDC_ODM') {
      setEDCFlag(true);
    }
  };

  const onBulkEdit = () => {
    setBulkEditMode(true);
  };

  const onBulkDelete = () => {
    setBulkDeleteMode(true);
  };

  return (
    <div>
      <Grid
        container
        style={{
          paddingLeft: '20px',
          paddingRight: '20px'
        }}>
        <Grid item xs={12}>
          {loading ? (
            <DataVizCard>
              <Loader />
            </DataVizCard>
          ) : (
            <Table
              title="Source Configuration"
              subtitle="Select the sources you want to configure for this study"
              columns={columns}
              isLoading={saveTriggered}
              rows={rows.map((row) => ({
                ...row,
                //   onRowEdit,
                //   onRowSave,
                //   onCancel,
                //   onDelete,
                editRow,
                //   editMode: true,
                editedRow,
                selectedRows,
                selectedDataRows,
                handleSelectRow,
                key: row.uuid,
                className: classNames(
                  (row.connection === NOT_CONNECTED && classes.error) ||
                    (selectedRows.includes(row.uuid) && classes.selected)
                )
              }))}
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                labelDisplayedRows: DisplayedRowsLabel,
                truncate: true
              }}
              CustomHeader={CustomButtonHeader}
              headerProps={{ onBulkEdit, onBulkDelete, selectedRows, selectedDataRows }}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default TableBulkEdit;
