import React, { useEffect, useRef } from 'react';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import Grid from 'apollo-react/components/Grid';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { BannerExample } from 'Components/Banner/Banner';
import ProjectStudyHeader from '../../Pages/DataStandardLibrary/GlobalStudyLibrary/Components/ProjectStudyHeader';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setSDHDataProductWorkflow } from 'Redux/Slice/SDHDataProductSlice';
import SDHDataProductSideBar from 'Components/SDHDataProductSideBar/SDHDataProductSideBar';
import { unwrapResult } from '@reduxjs/toolkit';
import { GetWorkflowModel } from 'Redux/Service/RuleEditorService';

const useStyles = makeStyles({
  studySidebar: {
    backgroundColor: '#fff',
    borderRight: '1px solid #ccc',
    marginTop: '56px',
    height: `calc(100vh - 113px)`
  },
  container: {
    maxHeight: `calc(100vh - 113px)`,
    marginTop: '57px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
});

export const SDHDataProductLayout = (props) => {
  const childRef = useRef();
  const classes = useStyles();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const dispatch = useDispatch();

  async function getWorkflow() {
    const data = await dispatch(GetWorkflowModel('SDHDataProduct')).then(unwrapResult);
    const { success, functionData, message } = data || {};
    if (success) {
      const sortedFD = functionData?.length > 0 && functionData.sort((a, b) => a.order - b.order);
      dispatch(setSDHDataProductWorkflow(sortedFD));
    } else {
      dispatch(showBanner({ variant: 'error', message }));
    }
  }

  useEffect(() => {
    getWorkflow();
  }, []);

  const handleSubmit = () => {
    childRef.current.handleSubmit();
  };

  return (
    <React.Fragment>
      <Header />
      <BannerExample />
      <Grid container>
        <Grid item xs={3} className={classes.studySidebar}>
          <SDHDataProductSideBar
            onSubmitRef={handleSubmit}
            isValidate={() => childRef.current.hasValidate()}
            handleStepperback={() => childRef.current.handleStepperback()}
            getPayload={() => childRef.current.getPayload()}
          />
        </Grid>
        <Grid item xs={9} className={classes.container}>
          <>
            {protocol !== undefined && <ProjectStudyHeader />}
            <Breadcrumb addStudy={true} />
          </>
          {React.cloneElement(props.children, { ref: childRef }, null)}
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
