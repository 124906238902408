import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Table from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import React, { useMemo, useState, useEffect, createRef } from 'react';
import EmptyTableComp from './EmptyTableComp';
import SearchDB from 'Images/search-db.svg';
import Refresh from 'apollo-react-icons/Refresh';
import Tooltip from 'apollo-react/components/Tooltip';
import Box from 'apollo-react/components/Box';
import styled from 'styled-components';
import Info from 'apollo-react-icons/Info';
import Switch from 'apollo-react/components/Switch';
import SuppQualIdentifier from 'Components/Common/SuppQualIdentifier';

const TextFieldFilter = ({
  accessor,
  filters,
  updateFilterValue,
  disableMDSColumns,
  disableHouseKeepingColumns
}) => {
  const tooltipTitle = useMemo(() => {
    if (disableMDSColumns) return 'Ruleset columns cannot be filtered';
    else if (disableHouseKeepingColumns) return 'System-generated columns cannot be filtered';
    else return '';
  }, [disableMDSColumns, disableHouseKeepingColumns]);
  return (
    <Tooltip
      title={tooltipTitle}
      placement={disableMDSColumns ? 'right' : disableHouseKeepingColumns ? 'left' : 'bottom'}>
      <span>
        <TextField
          value={filters[accessor]}
          name={accessor}
          onChange={updateFilterValue}
          fullWidth
          margin="none"
          size="small"
          disabled={disableMDSColumns || disableHouseKeepingColumns}
        />
      </span>
    </Tooltip>
  );
};

const TableHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

const TableHeaderRow = styled(Box)(({ marginTop, height }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  marginTop,
  height
}));

const CustomButtonHeader = (props) => {
  const {
    toggleFilters,
    handleSearchRemotely,
    handleClearRemoteFilters,
    mdsFlag,
    currentPath,
    domainCode,
    isDataFilteredRemotely,
    disableSearchInDB,
    isExactMatch,
    setIsExtactMatch
  } = props;

  const title = useMemo(() => {
    switch (currentPath) {
      case 'subject-setting-preview':
        return 'Subject Locators';
      case 'visit-setting-preview':
        return 'Visit Locators';
      case 'domain-preview':
      case 'mds-domain-preview':
        return 'Domain Data';
      default:
        return '';
    }
  }, [currentPath]);

  return (
    <TableHeader>
      <TableHeaderRow>
        <Box display={'flex'} alignItems={'flex-end'}>
          <Typography style={{ fontSize: '16px', fontWeight: '600' }} gutterBottom>
            {title}
          </Typography>
        </Box>
        <Box>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
            {mdsFlag && isDataFilteredRemotely && (
              <Button
                variant="text"
                onClick={handleClearRemoteFilters}
                size={'small'}
                style={{ marginRight: '10px' }}>
                <Refresh key={'Reset Table Data'} style={{ height: '18px' }} />
                Reset Table Data
              </Button>
            )}
            <Button
              variant="secondary"
              icon={Filter}
              onClick={toggleFilters}
              size={'small'}
              style={{}}>
              Filter
            </Button>
            {mdsFlag && (
              <Button
                variant="secondary"
                onClick={handleSearchRemotely}
                size={'small'}
                style={{ marginLeft: '10px' }}
                disabled={disableSearchInDB}>
                <img
                  key={'Search in database'}
                  src={SearchDB}
                  style={{ height: '15px', marginRight: '0.2rem' }}
                />
                Search in Database
              </Button>
            )}
          </div>
        </Box>
      </TableHeaderRow>
      <TableHeaderRow>
        <Box>
          <Typography variant="body2" gutterBottom>
            {domainCode}
          </Typography>
        </Box>

        {mdsFlag && (
          <Box
            component={'span'}
            sx={{
              '& .MuiFormControlLabel-label': { paddingBottom: '0px' },
              '& .MuiFormControlLabel-root': { marginRight: '0px' },
              transform: 'translateY(0.5rem)'
            }}>
            <Typography variant="body2" gutterBottom>
              Search in database for exact matches only?
              <Tooltip
                title={
                  "To search for multiple values in a column when searching in the database, simply separate each value by a comma. For example, 'value1, value2' will return results that match either value."
                }>
                <Info style={{ height: '1rem', opacity: '0.75', cursor: 'help' }}></Info>
              </Tooltip>
              <Switch
                checked={isExactMatch}
                onChange={(_e, checked) => setIsExtactMatch(checked)}
                size={'small'}></Switch>
            </Typography>
          </Box>
        )}
      </TableHeaderRow>
    </TableHeader>
  );
};

const createStringSearchFilter = (accessor) => {
  return function (row, filters) {
    let rowVal = row[accessor] ? row[accessor].toUpperCase() : '';
    let filterVal = filters[accessor] ? filters[accessor].toUpperCase() : '';
    const multipleFilters = filterVal.split(',').map((val) => val.trim());
    return multipleFilters.some(
      (_filterVal) => (multipleFilters?.length <= 1 || _filterVal) && rowVal.includes(_filterVal)
    );
  };
};

const compareStrings = (accessor, sortOrder) => {
  return function (rowA, rowB) {
    let stringA = rowA[accessor] ? rowA[accessor].toString().toUpperCase() : '';
    let stringB = rowB[accessor] ? rowB[accessor].toString().toUpperCase() : '';
    if (sortOrder === 'asc') {
      if (stringA < stringB) {
        return -1;
      }
      if (stringA > stringB) {
        return 1;
      }
      return 0;
    } else {
      if (stringA < stringB) {
        return 1;
      }
      if (stringA > stringB) {
        return -1;
      }
      return 0;
    }
  };
};

/**
 * useDomainDataTableConfig - Custom react hook to generate columns and
 * row data in the required structure for displaying domain data preview
 * @param domainData - Domain data to be formated and structured
 * @param masteredVarData - Mastered Variables - used to reorder the Domain data columns
 * @param houseKeepingPrefix - HouseKeepingColumns Prefix - used to reorder the Domain data columns and keep the house keeping columns to last
 * @returns {[Array, Array]} [columns, rows]
 */
export const useDomainDataTableConfig = (
  domainData = [],
  masteredVarData = [],
  houseKeepingPrefix
) => {
  const [cachedColumns, setCachedColumns] = useState([]);

  const rows = useMemo(() => {
    let aggregatedColumns = {};
    const _rows =
      domainData?.map((row) => {
        let _row = {};
        aggregatedColumns = { ...aggregatedColumns, ...row };
        for (let column in row) {
          _row[column.toLowerCase()] = row[column]?.toString() || '';
        }
        return _row;
      }) || [];

    const _columns = Object.keys(aggregatedColumns);
    if (cachedColumns?.length !== _columns?.length && _columns?.length > 0) {
      const columns = [
        ...new Set([...(masteredVarData?.map((_var) => _var.toUpperCase()) || []), ..._columns])
      ];
      columns.sort((colA, colB) => {
        if (
          !colA?.toLowerCase().startsWith(houseKeepingPrefix) &&
          colB?.toLowerCase().startsWith(houseKeepingPrefix)
        )
          return -1;
        else return 0;
      });
      setCachedColumns(columns);
    }
    return _rows;
  }, [domainData, masteredVarData, houseKeepingPrefix]);

  return [cachedColumns, rows];
};

const DomainDataTable = (props) => {
  const {
    domainCode,
    domainData,
    masteredVarData = [],
    mdsDomainActive,
    handleSearchInDB,
    handleResetTableData,
    isErrorResponse,
    houseKeepingPrefix,
    suppQualData,
    isDataFilteredRemotely,
    setDataFilteredRemote
  } = props;
  let currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const [filters, setFilters] = useState({});
  // const [cachedColumns, setCachedColumns] = useState([]);
  const [isDataFilteredLocal, setDataFilteredLocal] = useState();
  const [disableSearchInDB, setDisableSearchInDB] = useState(true);
  const [isExactMatch, setIsExtactMatch] = useState(true);
  const tableRef = createRef();

  const [cachedColumns, rows] = useDomainDataTableConfig(
    domainData,
    masteredVarData,
    houseKeepingPrefix
  );

  const columns = useMemo(() => {
    return cachedColumns.map((column) => {
      return {
        header: (
          <>
            <span>{column || ''}</span>
            {suppQualData?.includes(column) && (
             <SuppQualIdentifier /> 
            )}
          </>
        ),
        accessor: column.toLowerCase(),
        sortFunction: compareStrings,
        filterComponent: (filterProps) => (
          <TextFieldFilter
            {...filterProps}
            disableMDSColumns={
              mdsDomainActive &&
              masteredVarData?.some((item) => item.toLowerCase() === column.toLowerCase())
            }
            disableHouseKeepingColumns={
              mdsDomainActive && column?.toLowerCase()?.startsWith(houseKeepingPrefix)
            }
          />
        ),
        filterFunction: createStringSearchFilter(column.toLowerCase()),
        width: 150,
        fixedWidth: false
      };
    });
  }, [cachedColumns, houseKeepingPrefix, suppQualData]);

  useEffect(() => {
    setDisableSearchInDB(!Object.keys(filters).length);
  }, [isExactMatch]);

  const handleSearchRemotely = async () => {
    await handleSearchInDB(filters, isExactMatch);
    setDataFilteredRemote(true);
    setDataFilteredLocal(false);
    setDisableSearchInDB(true);
  };

  const handleClearRemoteFilters = async () => {
    tableRef?.current?.clearFilters();
    tableRef?.current?.toggleFilters();
    await handleResetTableData();
    setDataFilteredRemote(false);
    setDataFilteredLocal(false);
  };

  const handleFiltersChange = ({ filters: _filters }) => {
    const restructuredFilters = {};
    for (let column in _filters) {
      if (_filters[column] !== '') {
        restructuredFilters[column] = _filters[column]?.split(',').map((_) => _.trim());
      }
    }
    setFilters(restructuredFilters);
    if (Object.keys(restructuredFilters).length) {
      setDataFilteredLocal(true);
      setDisableSearchInDB(false);
    } else {
      setDisableSearchInDB(true);
    }
  };

  return (
    <Table
      columns={columns}
      rows={rows}
      rowsPerPageOptions={[10, 20, 50, 100, 'All']}
      initialSortedColumn={columns[0]?.accessor}
      initialSortOrder="asc"
      tablePaginationProps={{
        labelDisplayedRows: DisplayedRowsLabel,
        truncate: true
      }}
      hasScroll
      maxHeight={650}
      headerProps={{
        currentPath,
        handleSearchRemotely,
        handleClearRemoteFilters,
        domainCode,
        mdsFlag: mdsDomainActive,
        disableSearchInDB,
        isDataFilteredRemotely,
        isExactMatch,
        setIsExtactMatch
      }}
      CustomHeader={CustomButtonHeader}
      onChange={handleFiltersChange}
      emptyProps={{
        content: (
          <EmptyTableComp
            isDataFilteredLocal={isDataFilteredLocal}
            isDataFilteredRemotely={isDataFilteredRemotely}
            handleSearchRemotely={handleSearchRemotely}
            handleClearRemoteFilters={handleClearRemoteFilters}
            displayStandardMessage={!mdsDomainActive || isErrorResponse}
          />
        )
      }}
      ref={tableRef}
      filterDelay={1}
    />
  );
};

export default DomainDataTable;
