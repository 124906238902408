import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTrialName: '',
  description: '',
  isRunAllExecuted: false,
  nonEcrfDatasets: [],
  productMnemonic: '',
  libraryName: '',
  targetLibrary: {}
};

export const SDHDataProductSlice = createSlice({
  name: 'SDHDataProduct',
  initialState: {
    workFlowModel: {},
    nextEnabled: false,
    ...initialState
  },

  reducers: {
    setSDHDataProductWorkflow(state, { payload }) {
      state.workFlowModel = payload;
    },
    setNextEnabled(state, { payload }) {
      state.nextEnabled = payload;
    },
    setSelectedTrialName(state, { payload }) {
      state.selectedTrialName = payload;
    },
    setDescription(state, { payload }) {
      state.description = payload;
    },
    setProductMnemonic(state, { payload }) {
      state.productMnemonic = payload;
    },
    setLibraryName(state, { payload }) {
      state.libraryName = payload;
    },
    setTargetLibrary(state, { payload }) {
      state.targetLibrary = payload;
    },
    setIsRunAllExecuted(state, { payload }) {
      state.isRunAllExecuted = payload;
    },
    setNonEcrfDatasets(state, { payload }) {
      state.nonEcrfDatasets = payload;
    },
    resetNonEcrfState(state) {
      return { ...state, ...initialState, workFlowModel: state.workFlowModel };
    }
  }
});

export const {
  setSDHDataProductWorkflow,
  setNextEnabled,
  setTargetDataModal,
  setSelectedTrialName,
  setDescription,
  setProductMnemonic,
  setLibraryName,
  setTargetLibrary,
  setIsRunAllExecuted,
  setNonEcrfDatasets,
  resetNonEcrfState
} = SDHDataProductSlice.actions;
